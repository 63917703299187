import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { toggleMenu } from "../store/reducers/menu_reducer";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { get_auth_key, storeData, getStoredData } from "../util";
import { logout } from "../services/apiController";

const Banner = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isProfileMenuOpen, setProfileMenuOpen] = useState(false);
  const storedData = JSON.parse(getStoredData(get_auth_key()));

  const handleLogout = async (e) => {
    e.preventDefault();
    try {
      await logout();
      storeData(get_auth_key(), JSON.stringify({
        isClient: false,
        token: null
      }));
      navigate('/');
    } catch (err) {
      toast.error(err.message, { autoClose: 3000 });
    }
  }

  return (
    <React.Fragment>
      <nav className="my-navbar sticky-top">
        <div
          className="my-navbar-toggler"
          onClick={() => dispatch(toggleMenu())}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="30"
            height="30"
            fill="currentColor"
            className="bi bi-list"
            viewBox="0 0 16 16"
          >
            <path fillRule="evenodd" d="M2.5 11.5A.5.5 0 0 1 3 11h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4A.5.5 0 0 1 3 7h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4A.5.5 0 0 1 3 3h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z" />
          </svg>
        </div>
        <div className="my-navbar-brand">
          <Link to={`/members`}>
            <img
              src={`${process.env.PUBLIC_URL}/gym192.png`}
              alt="Brand logo"
              className="my-brand-logo"
            />
          </Link>
        </div>
        <div className="my-navbar-items me-auto not-in-mobile">
          <div className="my-nav-item banner-name">The Gym-Zone</div>
        </div>
        <div
          className="my-profile-toggler"
          onClick={() => setProfileMenuOpen(!isProfileMenuOpen)}
          style={{ position: "relative" }}
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-person-lines-fill" viewBox="0 0 16 16"><path d="M6 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm-5 6s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H1zM11 3.5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1h-4a.5.5 0 0 1-.5-.5zm.5 2.5a.5.5 0 0 0 0 1h4a.5.5 0 0 0 0-1h-4zm2 3a.5.5 0 0 0 0 1h2a.5.5 0 0 0 0-1h-2zm0 3a.5.5 0 0 0 0 1h2a.5.5 0 0 0 0-1h-2z"></path></svg>
          <div role="menu" className={isProfileMenuOpen ? "dropdown-menu dropdown-menu-right show" : "dropdown-menu dropdown-menu-right"} style={{ position: "absolute", willChange: "transform", top: "0px", right: "0px", transform: "translate3d(5px, 45px, 0px)", textAlign: "unset" }} x-placement="bottom-end">
            <div className="account-micro-view" style={{ padding: "0 1rem" }}><p className="mb-0">{storedData?.displayName}</p></div>
            <a onClick={handleLogout} className="logout-btn dropdown-item" style={{ padding: "0 1rem", color: "#dd2c00", backgroundColor: "#efefef", fontSize: "14px" }}>Logout</a>
          </div>
        </div>
      </nav>
    </React.Fragment>
  );
};

export default Banner;
