import React, { useEffect, useState } from "react";
import { get } from "../../services/apiController";
import { toast } from "react-toastify";
import { Form } from "react-bootstrap";

const List = React.memo(({ items }) => {
  return items.map((item, index) => (
    <option key={index} value={item?.id}>
      {item?.name}
    </option>
  ));
});

const Branch = ({ branchId, onChange, name, ...rest }) => {
  const [branches, setBranchList] = useState([]);
  const FetchBranches = () => {
    try {
      get(`/branches`).then((response) => {
        setBranchList(response?.data?.rows);
      });
    } catch (err) {
      toast.error(err?.response?.data?.data?.message || err?.message);
    }
  };

  useEffect(() => {
    FetchBranches();
  }, []);

  return (
    <Form.Select
      required={"required" in rest ? rest.required : false}
      disabled={"disabled" in rest ? rest.disabled : false}
      name={name}
      value={branchId}
      onChange={onChange}
      aria-label="Floating label select example"
      className="form-select"
    >
      <option value="">Select Branch</option>
      <List items={branches} />
    </Form.Select>
  );
};

export default React.memo(Branch);
