import React from "react";
import { Button, Modal, Row } from "react-bootstrap";

export default function CustomerView(props) {
  const { showViewModal, handleCloseViewModal, selectedCustomer } = props;
  return (
    <Modal
      show={showViewModal}
      onHide={handleCloseViewModal}
      backdrop="static"
      keyboard={false}
      size="xl"
      className="request-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title>Member Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {selectedCustomer && (
          <>
            <div>
              <div className="card-body">
                <Row className="top-row">
                  <div className="col-12 col-lg-3">
                    <div className="col-md-4 mt-5 mb-5">
                      <img
                        style={{
                          maxWidth: "230px",
                          maxHeight: "400px",
                          width: "auto",
                          height: "auto",
                        }}
                        src={
                          selectedCustomer?.image !== null
                            ? `${selectedCustomer?.image}`
                            : `${process.env.PUBLIC_URL}/no-image.png`
                        }
                        alt="Member"
                      />
                    </div>
                  </div>
                  <div className="col-12 col-lg-9">
                    <Row className="top-row">
                      <div className="col-md-4">
                        <dd className="display-title mb-1">Name</dd>
                        <dt className="display-value">
                          {selectedCustomer?.name}
                        </dt>
                      </div>
                      <div className="col-md-4">
                        <dd className="display-title mb-1">Email</dd>
                        <dt className="display-value">
                          {selectedCustomer?.email}
                        </dt>
                      </div>
                      <div className="col-md-4">
                        <dd className="display-title mb-1">Branch Name</dd>
                        <dt className="display-value">
                          {selectedCustomer?.Branch?.name}
                        </dt>
                      </div>
                      <div className="col-md-4">
                        <dd className="display-title mb-1">Mobile No.</dd>
                        <dt className="display-value">
                          {selectedCustomer?.phone}
                        </dt>
                      </div>

                      <div className="col-md-4">
                        <dd className="display-title mb-1">D.O.B.</dd>
                        <dt className="display-value">
                          {selectedCustomer?.dob}
                        </dt>
                      </div>
                      <div className="col-md-4">
                        <dd className="display-title mb-1">Age</dd>
                        <dt className="display-value">
                          {selectedCustomer?.age}
                        </dt>
                      </div>
                      <div className="col-md-4">
                        <dd className="display-title mb-1">Address</dd>
                        <dt className="display-value">
                          {selectedCustomer?.address}
                        </dt>
                      </div>
                      <div className="col-md-4">
                        <dd className="display-title mb-1">Gender</dd>
                        <dt className="display-value">
                          {selectedCustomer?.gender === "male"
                            ? "Male"
                            : selectedCustomer?.gender === "female"
                            ? "Female"
                            : null}
                        </dt>
                      </div>
                      <div className="col-md-4">
                        <dd className="display-title mb-1">Occupation</dd>
                        <dt className="display-value">
                          {selectedCustomer?.occupation}
                        </dt>
                      </div>
                      <div className="col-md-4">
                        <dd className="display-title mb-1">Measurement</dd>
                        <dt className="display-value">
                          Weight: {selectedCustomer?.weight}, Chest:{" "}
                          {selectedCustomer?.chest}, Biceps:{" "}
                          {selectedCustomer?.biceps}, Waist:{" "}
                          {selectedCustomer?.waist}
                        </dt>
                      </div>
                      <div className="col-md-4">
                        <dd className="display-title mb-1">Admission Date</dd>
                        <dt className="display-value">
                          {selectedCustomer?.admissionDate}
                        </dt>
                      </div>
                      <div className="col-md-4">
                        <dd className="display-title mb-1">Service</dd>
                        <dt className="display-value">
                          {selectedCustomer?.Service?.name}
                        </dt>
                      </div>
                      <div className="col-md-4">
                        <dd className="display-title mb-1">Duration</dd>
                        <dt className="display-value">
                          {selectedCustomer?.serviceDuration === "yearly"
                            ? "12 Month"
                            : selectedCustomer?.serviceDuration ===
                              "half_yearly"
                            ? "6 Month"
                            : selectedCustomer?.serviceDuration === "quarterly"
                            ? "3 Month"
                            : selectedCustomer?.serviceDuration === "monthly"
                            ? "1 Month"
                            : null}
                        </dt>
                      </div>
                      <div className="col-md-4">
                        <dd className="display-title mb-1">Amount</dd>
                        <dt className="display-value">
                          {selectedCustomer.amount}
                        </dt>
                      </div>
                      <div className="col-md-4">
                        <dd className="display-title mb-1">Next Due Date</dd>
                        <dt className="display-value">
                          {selectedCustomer.due_date}
                        </dt>
                      </div>
                    </Row>
                  </div>
                </Row>
              </div>
            </div>
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleCloseViewModal}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
