import React, { useState, useEffect, useRef } from "react";
import { get, post, put } from "../services/apiController";
import { TABLE_DEFAULT_LIMIT } from "../util/constant";
import {
  Table,
  Modal,
  Button,
  Form,
  FloatingLabel,
  Row,
  Col,
} from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";
import { DeleteIcon, EditIcon } from "./icons";
import { Link } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import PaginationInPage from "./Pagination";
import DeleteModal from "../components/modals/DeleteModal";
import moment from "moment";
import { GrDocumentExcel } from "react-icons/gr";
import Select from "react-select";
import { IoIosAddCircle } from "react-icons/io";

const Events = () => {
  const formFields = {
    name: "",
    amount: "",
    date: "",
  };
  const clickToDownload = useRef(null);
  const [totalRecords, setTotalRecords] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [eventForm, setEventForm] = useState(formFields);
  const [showConfirmModel, setShowConfirmModel] = useState(false);
  const [showConfirmModelAdd, setShowConfirmModelAdd] = useState(false);
  const [showViewMemberModelAdd, setShowViewMemberModelAdd] = useState(false);
  const [idToDeleted, setIdToDeleted] = useState("");
  const [deleteModal, setDeleteModal] = useState(false);
  const [eventList, setEventList] = useState([]);
  const [eventMemberList, setEventMemberList] = useState([]);
  const [customerList, setCoustomerList] = useState([]);
  const [url, setUrl] = useState("/events");
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [selectedMember, setSelectedMember] = useState([]);
  const [state, setState] = useState({
    customerId: "",
    startDate: "",
    endDate: "",
  });

  function getCustomerDropdown() {
    get("/customers").then((response) => {
      setCoustomerList(response.data.rows);
    });
  }
  const handleSelectChange = (selectedOption) => {
    if (selectedOption) {
      const selectedValues = selectedOption.map((option) => option.value);
      setEventForm({ ...eventForm, customerId: selectedValues });
      // const { name, value } = event.target;
      // setEventForm({ ...eventForm, [name]: value });
    } else {
      setState({ ...state, customerId: "" });
    }
  };

  const handleShow = () => {
    setShowConfirmModel(true);
    setEventForm(formFields);
  };

  const handleClose = () => {
    setShowConfirmModel(false);
  };

  const handleShowAddMember = (e, id) => {
    setShowConfirmModelAdd(true);
    // setEventForm(formFields);
    FetchMembers(id);
    e.preventDefault();
    const event = eventList.find((item) => item.id === +id);
    if (event) {
      setEventForm(event);
    }
  };
  const handleViewMember = (e, id) => {
    e.preventDefault();
    setSelectedEvent(id);
    setShowViewMemberModelAdd(true);
    FetchMembers(id);
  };
  const handleCloseViewMember = () => {
    setShowViewMemberModelAdd(false);
  };
  const handleCloseAddMember = () => {
    setSelectedMember([]);
    setShowConfirmModelAdd(false);
  };

  const FetchMembers = (id) => {
    let url = `/events/${id}`;
    try {
      get(url).then((response) => {
        const customers = response.data.rows;
        const customerIds = customers.map((item) => item.customerId);

        // Update the state with the new customerIds array
        setSelectedMember(customerIds);
        setEventMemberList(customers);
        setTotalRecords(response.data.count);
      });
    } catch (err) {
      toast.error(err.response.data.data.message);
    }
  };

  const handleEdit = (e, id) => {
    e.preventDefault();
    const event = eventList.find((item) => item.id === +id);
    if (event) {
      setEventForm(event);
      setShowConfirmModel(true);
    }
  };

  const handleChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    setEventForm({ ...eventForm, [name]: value });
  };
  const handleChangee = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    setEventForm({ ...eventForm, [name]: value });
  };

  const handleConfirmDeleteModal = (e, id, val) => {
    e.preventDefault();
    if (val === "memberDelete") {
      setUrl("/events/eventMember");
    } else {
      setUrl("/events");
    }
    setDeleteModal(true);
    setIdToDeleted(id);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if ("id" in eventForm) {
      try {
        put(eventForm, "/events/" + eventForm.id)
          .then((response) => {
            toast.success("Event updated successfully");
            setShowConfirmModel(false);
            FetchEvents();
          })
          .catch((err) => {
            toast.error(
              err?.response?.data?.data?.message || err?.response?.data?.message
            );
          });
      } catch (err) {
        toast.error(err?.response?.data?.message || err?.message, {
          autoClose: 3000,
        });
      }
    } else {
      try {
        post(eventForm, "/events/")
          .then((response) => {
            toast.success("Event added successfully");
            setShowConfirmModel(false);
            FetchEvents();
          })
          .catch((err) => {
            toast.error(
              err?.response?.data?.data?.message || err?.response?.data?.message
            );
          });
      } catch (err) {
        toast.error(err?.response?.data?.message || err?.message, {
          autoClose: 2000,
        });
      }
    }
  };
  const handleSubmitMember = (event) => {
    event.preventDefault();
    if ("id" in eventForm) {
      try {
        post(eventForm, "/events/addMember")
          .then((response) => {
            selectedMember.push(response.data?.customerId);
            toast.success("Event Member added successfully ");
            setShowConfirmModelAdd(false);
          })
          .catch((err) => {
            toast.error(
              err?.response?.data?.data?.message || err?.response?.data?.message
            );
          });
      } catch (err) {
        toast.error(err?.response?.data?.message || err?.message, {
          autoClose: 2000,
        });
      }
    }
  };

  const FetchEvents = (conditions) => {
    let url = "";
    if (conditions === "excel") {
      url = `/events/?conditionType=download`;
    } else {
      url = `/events/?page=${currentPage}&limit=${TABLE_DEFAULT_LIMIT}`;
    }
    try {
      get(url).then((response) => {
        if (conditions === "excel") {
          clickToDownload.current.href = `${process.env.REACT_APP_BASE_URL}/file/download?filePath=${response.data.filePath}`;
          setTimeout(() => {
            clickToDownload.current.click();
          }, 500);
        } else {
          setEventList(response.data.rows);
          setTotalRecords(response.data.count);
        }
      });
    } catch (err) {
      toast.error(err.response.data.data.message);
    }
  };

  const FetchEventsExcel = (conditions, id) => {
    let url = "";
    url = `/events/${id}?conditionType=download`;

    try {
      get(url).then((response) => {
        if (conditions === "excel") {
          clickToDownload.current.href = `${process.env.REACT_APP_BASE_URL}/file/download?filePath=${response.data.filePath}`;
          setTimeout(() => {
            clickToDownload.current.click();
          }, 500);
        } else {
          setEventList(response.data.rows);
          setTotalRecords(response.data.count);
        }
      });
    } catch (err) {
      toast.error(err.response.data.data.message);
    }
  };
  let callbackFunction;
  if (url === "/events/eventMember") {
    callbackFunction = (e) => {
      FetchMembers(selectedEvent);
    };
  } else {
    callbackFunction = (e) => {
      FetchEvents();
    };
  }

  useEffect(() => {
    getCustomerDropdown();
  }, []);

  useEffect(() => {
    FetchEvents();
  }, [currentPage]);

  return (
    <React.Fragment>
      <ToastContainer />
      <div className="card">
        <div
          className="card-header white-color"
          style={{
            display: "flex",
            justifyContent: "flex-end",
            padding: "1rem 1.7rem",
          }}
        >
          <h4 className="card-title mb-0" style={{ fontSize: "16px" }}>
            Events | {totalRecords ?? 0}
          </h4>
          <button
            type="button"
            className="btn app-btn-default dark-color "
            onClick={handleShow}
          >
            Add New Event
          </button>

          <a
            ref={clickToDownload}
            href="/"
            target="_blank"
            className="hiddenItem"
          >
            HiddenClick
          </a>
          <GrDocumentExcel
            onClick={() => FetchEvents("excel")}
            style={{ width: "70px", height: "30px", cursor: "pointer" }}
            title="Download Excel report"
            className="react-icons green"
          />
        </div>
        <div className="card-body px-0 vehicle-requests-table pt-0 pb-0">
          <div className="row">
            <div className="col">
              <Table responsive striped>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Event Name</th>
                    <th>Event Date</th>
                    <th>Event Amount</th>
                    <th>Actions</th>
                    <th>Excel</th>
                  </tr>
                </thead>
                <tbody>
                  {eventList.map((item, index) => {
                    return (
                      <tr key={item?.id}>
                        <td>
                          {(currentPage - 1) * TABLE_DEFAULT_LIMIT +
                            (index + 1)}
                        </td>
                        <td>{item?.name}</td>
                        <td>{moment(item?.date).format("DD/MM/YYYY")}</td>
                        <td>{item?.amount}</td>
                        <td className="actions">
                          <span
                            onClick={(e) => handleShowAddMember(e, item?.id)}
                            className="edit-icon"
                          >
                            <IoIosAddCircle size={25} color="brown" />
                          </span>

                          <span
                            onClick={(e) => handleEdit(e, item?.id)}
                            className="edit-icon"
                          >
                            <EditIcon />
                          </span>
                          <Link
                            onClick={(e) =>
                              handleConfirmDeleteModal(
                                e,
                                item?.id,
                                "eventDelete"
                              )
                            }
                            className="delete"
                            to="/"
                          >
                            <DeleteIcon />
                          </Link>
                        </td>
                        <td>
                          <a
                            ref={clickToDownload}
                            href="/"
                            target="_blank"
                            className="hiddenItem"
                          >
                            HiddenClick
                          </a>
                          <GrDocumentExcel
                            onClick={() => FetchEventsExcel("excel", item.id)}
                            style={{
                              width: "55px",
                              height: "25px",
                              cursor: "pointer",
                            }}
                            title="Download Excel report"
                            className="react-icons green"
                          />
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
              <PaginationInPage
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                totalRecords={totalRecords}
                TABLE_DEFAULT_LIMIT={TABLE_DEFAULT_LIMIT}
              />
            </div>
          </div>
        </div>
      </div>
      <Modal
        size="xl"
        show={showViewMemberModelAdd}
        backdrop="static"
        keyboard={false}
        className="request-modal"
      >
        <Modal.Header closeButton onClick={handleCloseViewMember}>
          <Modal.Title>Event Member List</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form autoComplete="off">
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Event</th>
                  <th>Event Member</th>
                  <th>Amount</th>
                  <th>Delete</th>
                </tr>
              </thead>
              <tbody>
                {eventMemberList.map((item, index) => {
                  return (
                    <tr key={item?.id}>
                      <td>
                        {(currentPage - 1) * TABLE_DEFAULT_LIMIT + (index + 1)}
                      </td>
                      <td>{item?.Event.name}</td>
                      <td>{item?.Customer?.name}</td>
                      <td>{item?.amount}</td>
                      <td>
                        <Link
                          onClick={(e) =>
                            handleConfirmDeleteModal(
                              e,
                              item?.id,
                              "memberDelete"
                            )
                          }
                          className="delete"
                          to="/"
                        >
                          <DeleteIcon />
                        </Link>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </Form>
        </Modal.Body>
      </Modal>

      <Modal
        size="md"
        show={showConfirmModelAdd}
        backdrop="static"
        keyboard={false}
        className="request-modal"
      >
        <Modal.Header closeButton onClick={handleCloseAddMember}>
          <Modal.Title>
            {"id" in eventForm ? "Add MEMBER" : "Add MEMBER"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form autoComplete="off">
            <Row>
              <Col xs={12}>
                <FloatingLabel
                  controlId="floatingInput"
                  label="Event Name"
                  className="mb-3"
                >
                  <Form.Control
                    className="input"
                    value={eventForm.name}
                    name="name"
                    onChange={handleChangee}
                    type="text"
                    placeholder="Event Name"
                    required
                  />
                </FloatingLabel>
              </Col>
              <Col xs={12}>
                <Select
                  onChange={handleSelectChange}
                  className="basic-single"
                  classNamePrefix="select"
                  placeholder="Member Name"
                  isSearchable
                  isClearable
                  name="customerId"
                  isMulti // Enable multi-select
                  options={customerList
                    .filter((item) => !selectedMember.includes(item.id)) // Filter out items present in selectedMember
                    .sort((a, b) => a.name.localeCompare(b.name))
                    .map((item) => ({ value: item.id, label: item.name }))}
                  styles={{
                    control: (provided, state) => ({
                      ...provided,
                      minHeight: "55px", // Adjust the height value as needed
                    }),
                  }}
                />
              </Col>

              <Col xs={12} className="mt-3">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Amount"
                  className="mb-3"
                >
                  <Form.Control
                    className="input"
                    value={eventForm.amount}
                    name="amount"
                    onChange={handleChangee}
                    type="number"
                    placeholder="Amount"
                    required
                  />
                </FloatingLabel>
              </Col>
            </Row>

            <div className="container text-center ">
              <div class="row">
                <div class="col">
                  <Button
                    type="submit"
                    onClick={handleSubmitMember}
                    className="add-request-btn mt-4 mb-3"
                  >
                    {"id" in eventForm ? "Add" : "Add"}
                  </Button>
                </div>
                <div class="col">
                  <Button
                    type="submit"
                    onClick={(e) => handleViewMember(e, eventForm.id)}
                    className="add-request-btn mt-4 mb-3"
                  >
                    View Member
                  </Button>
                </div>
              </div>
            </div>
          </Form>
        </Modal.Body>
      </Modal>

      <Modal
        size="md"
        show={showConfirmModel}
        backdrop="static"
        keyboard={false}
        className="request-modal"
      >
        <Modal.Header closeButton onClick={handleClose}>
          <Modal.Title>
            {"id" in eventForm ? "Edit Event" : "Add Event"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form autoComplete="off">
            <Row>
              <Col xs={12}>
                <FloatingLabel
                  controlId="floatingInput"
                  label="Event Name"
                  className="mb-3"
                >
                  <Form.Control
                    className="input"
                    value={eventForm.name}
                    name="name"
                    onChange={handleChange}
                    type="text"
                    placeholder="Event Name"
                    required
                  />
                </FloatingLabel>
              </Col>
              <Col xs={12}>
                <FloatingLabel
                  controlId="floatingInput"
                  label="Event Date"
                  className="mb-3"
                >
                  <Form.Control
                    className="input"
                    value={eventForm.date}
                    name="date"
                    onChange={handleChange}
                    type="date"
                    required
                  />
                </FloatingLabel>
              </Col>
              <Col xs={12}>
                <FloatingLabel
                  controlId="floatingInput"
                  label="Amount"
                  className="mb-3"
                >
                  <Form.Control
                    className="input"
                    value={eventForm.amount}
                    name="amount"
                    onChange={handleChange}
                    type="number"
                    placeholder="Amount"
                    required
                  />
                </FloatingLabel>
              </Col>
            </Row>
            <div className="container text-center">
              <Button
                type="submit"
                onClick={handleSubmit}
                className="add-request-btn mt-4 mb-3"
              >
                {"id" in eventForm ? "Update" : "Add"}
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
      {deleteModal && (
        <DeleteModal
          title={"Delete Events"}
          description={"Are you sure? Action cannot be undone."}
          deleteModal={deleteModal}
          setDeleteModal={setDeleteModal}
          idToDeleted={idToDeleted}
          url={url}
          callback={callbackFunction}
        />
      )}
    </React.Fragment>
  );
};

export default Events;
