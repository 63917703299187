import React, { useState, useEffect, useRef } from "react";
import { get, post, put, destroy } from "../services/apiController";
import { TABLE_DEFAULT_LIMIT } from "../util/constant";
import {
  Table,
  Modal,
  Button,
  Form,
  FloatingLabel,
  Row,
  Col,
} from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";
import { DeleteIcon, EditIcon } from "./icons";
import { Link } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import AlertIcon from "../../src/img.svg";
import PaginationInPage from "./Pagination";
import { GrDocumentExcel } from "react-icons/gr";

const BranchList = () => {
  const formFields = {
    name: "",
    address: "",
    contactNumber: "",
    contactPerson: "",
  };
  const clickToDownload = useRef(null);
  const [totalRecords, setTotalRecords] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [branchForm, setBranchForm] = useState(formFields);
  const [showConfirmModel, setShowConfirmModel] = useState(false);
  const [idToDeleted, setIdToDeleted] = useState("");
  const [deleteModel, setDeleteModel] = useState(false);
  const [branchList, setBranchList] = useState([]);

  const handleShow = () => {
    setShowConfirmModel(true);
    setBranchForm(formFields);
  };

  const handleClose = () => {
    setShowConfirmModel(false);
  };

  const handleEdit = (e, id) => {
    e.preventDefault();
    try {
      get("/branches/?id=" + id).then((branch) => {
        if (branch.data.rows.length) {
          const branchData = branch.data.rows[0];
          setBranchForm(branchData);
          setShowConfirmModel(true);
        } else {
          toast.error("No branch found with this name");
        }
      });
    } catch (error) {
      toast.error(
        error?.response?.data?.data?.rows?.message || "Something went wrong"
      );
    }
  };

  const handleChange = (event) => {
    event.preventDefault();
    let name = event.target.name;
    let value = event.target.value;
    setBranchForm({ ...branchForm, [name]: value });
  };

  const handleRemove = (event, id) => {
    event.preventDefault();
    try {
      destroy("/branches/" + id)
        .then((response) => {
          toast.success("Branch Deleted successfully");
          setDeleteModel(false);
          getBranches();
        })
        .catch((err) => {
          toast.error(err?.response?.data?.message);
        });
    } catch (err) {
      toast.error(err.message, { autoClose: 3000 });
    }
  };

  const handleConfirmDeleteModal = (e, id) => {
    e.preventDefault();
    setDeleteModel(true);
    setIdToDeleted(id);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if ("id" in branchForm) {
      try {
        put(branchForm, "/branches/" + branchForm.id)
          .then((response) => {
            toast.success("Branch updated successfully");
            setShowConfirmModel(false);
            getBranches();
          })
          .catch((err) => {
            toast.error(
              err?.response?.data?.data?.message || err?.response?.data?.message
            );
          });
      } catch (err) {
        toast.error(err?.response?.data?.message || err?.message, {
          autoClose: 3000,
        });
      }
    } else {
      try {
        post(branchForm, "/branches/")
          .then((response) => {
            toast.success("Branch added successfully");
            setShowConfirmModel(false);
            getBranches();
          })
          .catch((err) => {
            toast.error(
              err?.response?.data?.data?.message || err?.response?.data?.message
            );
          });
      } catch (err) {
        toast.error(err?.response?.data?.message || err?.message, {
          autoClose: 2000,
        });
      }
    }
  };

  const getBranches = (conditions) => {
    try {
      let url = "";
      if (conditions === "excel") {
        url = `/branches/?conditionType=download`;
      } else {
        url = `/branches/?page=${currentPage}&limit=${TABLE_DEFAULT_LIMIT}`;
      }
      get(url).then((response) => {
        if (conditions === "excel") {
          clickToDownload.current.href = `${process.env.REACT_APP_BASE_URL}/file/download?filePath=${response.data.filePath}`;
          setTimeout(() => {
            clickToDownload.current.click();
          }, 500);
        } else {
          setBranchList(response.data.rows);
          setTotalRecords(response.data.count);
        }
      });
    } catch (err) {
      toast.error(err.response.data.data.message);
    }
  };

  useEffect(() => {
    getBranches();
  }, [currentPage]);

  return (
    <React.Fragment>
      <ToastContainer />
      <div className="card">
        <div
          className="card-header white-color"
          style={{
            display: "flex",
            justifyContent: "flex-end",
            padding: "1rem 1.7rem",
          }}
        >
          <h4 className="card-title mb-0" style={{ fontSize: "16px" }}>
            Branches | {totalRecords ?? 0}
          </h4>
          <button
            type="button"
            className="btn app-btn-default dark-color "
            onClick={handleShow}
          >
            Add New Branch
          </button>
          <a
            ref={clickToDownload}
            href="/"
            target="_blank"
            className="hiddenItem"
          >
            HiddenClick
          </a>
          <GrDocumentExcel
            onClick={() => getBranches("excel")}
            style={{ width: "70px", height: "30px", cursor: "pointer" }}
            title="Download Excel report"
            className="react-icons green"
          />
        </div>
        <div className="card-body px-0 vehicle-requests-table pt-0 pb-0">
          <div className="row">
            <div className="col">
              <Table responsive striped>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Branch name</th>
                    <th>Address</th>
                    <th>Contact Number</th>
                    <th>Contact Person</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {branchList.map((item, index) => {
                    return (
                      <tr key={item?.id}>
                        <td>
                          {(currentPage - 1) * TABLE_DEFAULT_LIMIT +
                            (index + 1)}
                        </td>
                        <td>{item?.name}</td>
                        <td>{item?.address}</td>
                        <td>{item?.contactNumber}</td>
                        <td>{item?.contactPerson}</td>
                        <td className="actions">
                          <span
                            onClick={(e) => handleEdit(e, item?.id)}
                            className="edit-icon"
                          >
                            <EditIcon />
                          </span>
                          <Link
                            onClick={(e) =>
                              handleConfirmDeleteModal(e, item?.id)
                            }
                            className="delete"
                            to="/"
                          >
                            <DeleteIcon />
                          </Link>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
              <PaginationInPage
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                totalRecords={totalRecords}
                TABLE_DEFAULT_LIMIT={TABLE_DEFAULT_LIMIT}
              />
            </div>
          </div>
        </div>
      </div>
      <Modal
        size="lg"
        show={showConfirmModel}
        backdrop="static"
        keyboard={false}
        className="request-modal"
      >
        <Modal.Header closeButton onClick={handleClose}>
          <Modal.Title>
            {"id" in branchForm ? "Edit Branch" : "Add Branch"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form autoComplete="off">
            <Row>
              <Col xs={6}>
                <FloatingLabel
                  controlId="floatingInput"
                  label="Branch Name"
                  className="mb-3"
                >
                  <Form.Control
                    className="input"
                    value={branchForm.name}
                    name="name"
                    onChange={handleChange}
                    type="text"
                    placeholder="Branch Name"
                    required
                  />
                </FloatingLabel>
              </Col>
              <Col xs={6}>
                <FloatingLabel
                  controlId="floatingInput"
                  label="Address"
                  className="mb-3"
                >
                  <Form.Control
                    className="input"
                    value={branchForm.address}
                    name="address"
                    onChange={handleChange}
                    type="text"
                    placeholder="Address"
                    required
                  />
                </FloatingLabel>
              </Col>
            </Row>
            <Row>
              <Col xs={6}>
                <FloatingLabel
                  controlId="floatingInput"
                  label="Contact Number"
                  className="mb-3"
                >
                  <Form.Control
                    className="input"
                    value={branchForm.contactNumber}
                    name="contactNumber"
                    onChange={handleChange}
                    type="number"
                    placeholder="contactNumber"
                    required
                  />
                </FloatingLabel>
              </Col>
              <Col xs={6}>
                <FloatingLabel
                  controlId="floatingInput"
                  label="Contact Person"
                  className="mb-3"
                >
                  <Form.Control
                    className="input"
                    value={branchForm.contactPerson}
                    name="contactPerson"
                    onChange={handleChange}
                    type="text"
                    placeholder="contactPerson"
                    required
                  />
                </FloatingLabel>
              </Col>
            </Row>
            <div className="container text-center">
              <Button
                type="submit"
                onClick={handleSubmit}
                className="add-request-btn mt-4 mb-3"
              >
                {"id" in branchForm ? "Update" : "Add"}
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
      <Modal
        size="sm"
        show={deleteModel}
        backdrop="static"
        keyboard={false}
        className="request-modal"
      >
        <Modal.Header className="bg-danger text-light py-2">
          <Modal.Title>Delete Branch Request</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex justify-content-start">
            <img src={AlertIcon} alt="alert icon" />
            <p className="ps-3 mb-0">Are you sure? Action cannot be undone.</p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="btn-wrapper">
            <button
              onClick={() => setDeleteModel(false)}
              className="btn app-btn-default btn-sm"
            >
              Cancel
            </button>
            <button
              onClick={(event) => handleRemove(event, idToDeleted)}
              className="btn app-btn-danger btn-sm"
            >
              Delete
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
};

export default BranchList;
