import React, { useState, useEffect, useRef } from "react";
import { get, post, put, destroy } from "../services/apiController";
import { TABLE_DEFAULT_LIMIT } from "../util/constant";
import {
  Table,
  Modal,
  Button,
  Form,
  FloatingLabel,
  Row,
  Col,
} from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";
import { DeleteIcon, EditIcon } from "./icons";
import { Link } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import AlertIcon from "../../src/img.svg";
import PaginationInPage from "./Pagination";
import { GrDocumentExcel } from "react-icons/gr";

const Users = () => {
  const formFields = {
    name: "",
    email: "",
    phone: "",
    password: "",
    role: "select",
    branchId: "",
  };
  const clickToDownload = useRef(null);
  const [totalRecords, setTotalRecords] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [userList, setUserList] = useState([]);
  const [userForm, setUserForm] = useState(formFields);
  const [showConfirmModel, setShowConfirmModel] = useState(false);
  const [idToDeleted, setIdToDeleted] = useState("");
  const [deleteModel, setDeleteModel] = useState(false);
  const [branchList, setBranchList] = useState([]);

  const handleShow = () => {
    setShowConfirmModel(true);
    setUserForm(formFields);
  };

  const handleClose = () => {
    setShowConfirmModel(false);
  };

  const handleEdit = (e, id) => {
    e.preventDefault();
    try {
      get("/users/?id=" + id).then((user) => {
        if (user.data.rows.length) {
          const userData = user.data.rows[0];
          delete userData.password;
          setUserForm(userData);
          setShowConfirmModel(true);
        } else {
          toast.error("Invalid user id");
        }
      });
    } catch (err) {
      toast.error(
        err?.response?.data?.data?.rows?.message || "Something went wrong"
      );
    }
  };

  const handleChange = (event) => {
    event.preventDefault();
    let name = event.target.name;
    let value = event.target.value;
    setUserForm({ ...userForm, [name]: value });
  };

  const handleRemove = (event, id) => {
    event.preventDefault();
    try {
      destroy("/users/" + id)
        .then((response) => {
          toast.success("User Deleted successfully");
          setDeleteModel(false);
          getUsers();
        })
        .catch((err) => {
          toast.error(err?.response?.data?.message);
        });
    } catch (err) {
      toast.error(err.message, { autoClose: 3000 });
    }
  };

  const handleConfirmDeleteModal = (e, id) => {
    e.preventDefault();
    setDeleteModel(true);
    setIdToDeleted(id);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if ("id" in userForm) {
      try {
        put(userForm, "/users/" + userForm.id)
          .then((response) => {
            toast.success("User updated successfully");
            setShowConfirmModel(false);
            getUsers();
          })
          .catch((err) => {
            toast.error(
              err?.response?.data?.data?.message || err?.response?.data?.message
            );
          });
      } catch (err) {
        toast.error(err.message, { autoClose: 3000 });
      }
    } else {
      try {
        post(userForm, "/users")
          .then((response) => {
            toast.success("User added successfully");
            setShowConfirmModel(false);
            getUsers();
          })
          .catch((err) => {
            toast.error(
              err?.response?.data?.data?.message || err?.response?.data?.message
            );
          });
      } catch (err) {
        toast.error(err.response.data.data.message, { autoClose: 2000 });
      }
    }
  };

  function getUsers(conditions) {
    try {
      let url = "";
      if (conditions === "excel") {
        url = `/users/?conditionType=download`;
      } else {
        url = `/users/?page=${currentPage}&limit=${TABLE_DEFAULT_LIMIT}`;
      }
      get(url).then((response) => {
        if (conditions === "excel") {
          clickToDownload.current.href = `${process.env.REACT_APP_BASE_URL}/file/download?filePath=${response.data.filePath}`;
          setTimeout(() => {
            clickToDownload.current.click();
          }, 500);
        } else {
          setUserList(response.data.rows);
          setTotalRecords(response.data.count);
        }
      });
    } catch (err) {
      toast.error(err.response.data.data.message);
    }
  }

  function getBranchDropdown() {
    get("/branches").then((response) => {
      setBranchList(response.data.rows);
    });
  }

  useEffect(() => {
    getUsers();
    getBranchDropdown();
  }, []);

  return (
    <React.Fragment>
      <ToastContainer />
      <div className="card">
        <div
          className="card-header white-color"
          style={{
            display: "flex",
            justifyContent: "flex-end",
            padding: "1rem 1.7rem",
          }}
        >
          <h4 className="card-title mb-0" style={{ fontSize: "16px" }}>
            Users | {totalRecords ?? 0}
          </h4>
          <button
            type="button"
            className="btn app-btn-default dark-color "
            onClick={handleShow}
          >
            Add New User
          </button>
          <a
            ref={clickToDownload}
            href="/"
            target="_blank"
            className="hiddenItem"
          >
            HiddenClick
          </a>
          <GrDocumentExcel
            onClick={() => getUsers("excel")}
            style={{ width: "70px", height: "30px", cursor: "pointer" }}
            title="Download Excel report"
            className="react-icons green"
          />
        </div>
        <div className="card-body px-0 vehicle-requests-table pt-0 pb-0">
          <div className="row">
            <div className="col">
              <Table responsive striped>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Name</th>
                    <th>Email Address</th>
                    <th>Phone</th>
                    <th>Role</th>
                    <th>Branch</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {userList.map((item, index) => {
                    return (
                      <tr key={item?.id}>
                        <td>
                          {(currentPage - 1) * TABLE_DEFAULT_LIMIT +
                            (index + 1)}
                        </td>
                        <td>{item?.name}</td>
                        <td>{item?.email}</td>
                        <td>{item?.phone}</td>
                        <td>{item?.role === "admin" ? "Admin" : "Manager"}</td>
                        <td>{item?.Branch?.name}</td>
                        <td className="actions">
                          <span
                            onClick={(e) => handleEdit(e, item?.id)}
                            className="edit-icon"
                          >
                            <EditIcon />
                          </span>
                          <Link
                            onClick={(e) =>
                              handleConfirmDeleteModal(e, item?.id)
                            }
                            className="delete"
                            to="/"
                          >
                            <DeleteIcon />
                          </Link>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
              <PaginationInPage
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                totalRecords={totalRecords}
                TABLE_DEFAULT_LIMIT={TABLE_DEFAULT_LIMIT}
              />
            </div>
          </div>
        </div>
      </div>
      <Modal
        size="lg"
        show={showConfirmModel}
        backdrop="static"
        keyboard={false}
        className="request-modal"
      >
        <Modal.Header closeButton onClick={handleClose}>
          <Modal.Title>
            {"id" in userForm ? "Update User" : "Add User"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form autoComplete="off">
            <Row>
              <Col xs={4}>
                <FloatingLabel label="Name" className="mb-3">
                  <Form.Control
                    className="input"
                    name="name"
                    onChange={handleChange}
                    value={userForm.name}
                    type="text"
                    placeholder="Name"
                    required
                  />
                </FloatingLabel>
              </Col>
              <Col xs={4}>
                <FloatingLabel label="Email Address" className="mb-3">
                  <Form.Control
                    className="input"
                    onChange={handleChange}
                    value={userForm.email}
                    name="email"
                    type="email"
                    placeholder="Email Address"
                  />
                </FloatingLabel>
              </Col>
              <Col xs={4}>
                <FloatingLabel label="Phone No." className="mb-3">
                  <Form.Control
                    className="input"
                    onChange={handleChange}
                    value={userForm.phone}
                    name="phone"
                    type="text"
                    placeholder="Phone No."
                    required
                  />
                </FloatingLabel>
              </Col>
            </Row>
            <Row>
              <Col xs={4}>
                <FloatingLabel label="Password" className="mb-3">
                  <Form.Control
                    className="input"
                    onChange={handleChange}
                    value={userForm.password}
                    name="password"
                    type="password"
                    placeholder="Password"
                    required
                  />
                </FloatingLabel>
              </Col>
              <Col xs={4}>
                <FloatingLabel label="Role" className="mb-3">
                  <select
                    name="role"
                    value={userForm.role}
                    onChange={handleChange}
                    aria-label="Floating label select example"
                    className="form-select"
                    required
                  >
                    <option value="">Select Role</option>
                    <option value="admin">Admin</option>
                    <option value="manager">Branch Manager</option>
                  </select>
                </FloatingLabel>
              </Col>
              {userForm.role === "manager" ? (
                <Col xs={4}>
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Branch Name"
                    className="mb-3"
                  >
                    <select
                      name="branchId"
                      value={userForm.branchId}
                      onChange={handleChange}
                      aria-label="Floating label select example"
                      className="form-select"
                      required
                    >
                      <option value="">Select Branch</option>
                      {branchList.map((item) => {
                        return (
                          <option key={item?.id} value={item.id}>
                            {item?.name}
                          </option>
                        );
                      })}
                    </select>
                  </FloatingLabel>
                </Col>
              ) : (
                ""
              )}
            </Row>
            <div className="container text-center">
              <Button
                onClick={handleSubmit}
                type="submit"
                className="add-request-btn mt-4 mb-3 justify-content-center"
              >
                {"id" in userForm ? "Update User" : "Add User"}
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
      <Modal
        size="sm"
        show={deleteModel}
        backdrop="static"
        keyboard={false}
        className="request-modal"
      >
        <Modal.Header className="bg-danger text-light py-2">
          <Modal.Title>Delete User</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex justify-content-start">
            <img src={AlertIcon} alt="alert icon" />
            <p className="ps-3 mb-0">Are you sure? Action cannot be undone.</p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="btn-wrapper">
            <button
              onClick={() => setDeleteModel(false)}
              className="btn app-btn-default btn-sm"
            >
              Cancel
            </button>
            <button
              onClick={(event) => handleRemove(event, idToDeleted)}
              className="btn app-btn-danger btn-sm"
            >
              Delete
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
};

export default Users;
