import axios from "axios";
import {getStoredData, get_auth_key} from '../util';

export const get = async (URL) => {
  const storedData = JSON.parse(getStoredData(get_auth_key()));
  const headers = {
    "Accept": "application/json",
    "Content-Type": "application/json",
    'x-access-token': storedData.token
  }
  try {
    const res = await axios.get(process.env.REACT_APP_BASE_URL + URL, {
      headers: headers
    });
    return res.data;
  } catch (e) {
    throw e;
  }
};

export const post = async (body, URL, isJSONRequest = true) => {
  const storedData = JSON.parse(getStoredData(get_auth_key()));
  const headers = {
    "Accept": "application/json",
    "Content-Type": isJSONRequest ? 'application/json' : 'multipart/form-data',
    'x-access-token': storedData.token
  };
  try {
    const res = await axios.post(process.env.REACT_APP_BASE_URL + URL, body, {
      headers: headers
    });
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const put = async (body, URL, isJSONRequest = true) => {
  const storedData = JSON.parse(getStoredData(get_auth_key()));
  const headers = {
    "Accept": "application/json",
    "Content-Type": isJSONRequest ? 'application/json' : 'multipart/form-data',
    'x-access-token': storedData.token,
  }
  try {
    const res = await axios.put(process.env.REACT_APP_BASE_URL + URL, body, {
      headers: headers
    });
    return res.data;
  } catch (e) {
    throw e;
  }
};

export const destroy = async (URL) => {
  const storedData = JSON.parse(getStoredData(get_auth_key()));

  const headers = {
    "Accept": "application/json",
    "Content-Type": "application/json",
    'x-access-token': storedData.token,
  }
  try {
    const res = await axios.delete(process.env.REACT_APP_BASE_URL + URL, {
      headers: headers
    });
    return await res.data;
  } catch (e) {
    throw e
  }
};

//LOGOUT
export const logout = async () => {
  const storedData = JSON.parse(getStoredData(get_auth_key()));
  const settings = {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      'x-access-token': storedData.token,
    }
  };
  try {
    const res = await axios.get(process.env.REACT_APP_BASE_URL + '/users/logout', {headers: settings.headers});
    return await res.data;
  } catch (e) {
    throw e
  }
};