import React from "react";
import { Link } from "react-router-dom";

const Home = () => {
  return (
    <React.Fragment>
      <div className="dash-count-parent-wrapper">
        <VehicleRequestCount link="/users" title="Users"/>
        <VehicleRequestCount link="/branches" title="Branches"/>
        <VehicleRequestCount link="/members" title="Members"/>
      </div>
    </React.Fragment>
  );
};

const DashCountDetail = ({ link, title, caption, value }) => {
  return (
    <div className="card dash-count-card">
      <div className="card-body">
        <div className="dash-card-wrap">
          <div className="card-count-info">
            <p className="card-title title">{title}</p>
            {caption && <p className="card-title caption">{caption}</p>}
          </div>
          <div className="card-count-value">
            {link ? <p><Link to={link} className="link-text">{value}</Link></p> : <p>{value}</p>}
          </div>
        </div>
      </div>
    </div>
  );
};

const VehicleCount = ({ link, title, count, caption }) => {
  return <DashCountDetail link={link} title={title} value={count} caption={caption} />;
};

const VehicleRequestCount = ({ link, title, caption, count }) => {
  return (
    <DashCountDetail
      link={link}
      title={title}
      value={count}
      caption={caption}
    />
  );
};

export default Home;
