import { getStoredData, get_auth_key, storeData } from "../../util";
const { createSlice } = require("@reduxjs/toolkit");

export const account = createSlice({
  name: "account",
  initialState: {
    isLoggedIn: false,
    detail: null
  },
  reducers: {
    setUserLogin: (state, action) => {
      let storredData = getStoredData(get_auth_key());
      if (storredData) {
        storredData = JSON.parse(storredData);
        if (storredData && storredData.displayName) {
          state.isLoggedIn = true;
          state.detail = {
            ...storredData,
          };
        } else {
          state.isLoggedIn = false;
          state.detail = null;
        }
      } else {
        state.isLoggedIn = false;
        state.detail = null;
      }
    },
    setUserLogout: (state, action) => {
      state.isLoggedIn = false;
      state.detail = null;
    },
    setAppFactory: (state, action) => {
      state.detail.factoryId = action.payload.factoryId || null;
      storeData(get_auth_key(), JSON.stringify({ ...state.detail }));
    }
  },
});

export const { setUserLogin, setAppFactory, setUserLogout } = account.actions;
export const isLoggedIn = (state) => state.account.isLoggedIn;
export const loggedInUser = (state) => state.account.user;
export const factoryId = (state) => state.account.detail?.factoryId;
export default account.reducer;