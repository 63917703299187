import React from "react";
import { HashRouter as Router, Route, Routes, Outlet } from "react-router-dom";
import "./App.css";
import "./style/theme.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import Banner from "./layout/Banner";
import SideBar from "./layout/SideBar";
import Home from "./components/dashboard/Home";
import Login from "./components/auth/Login";
import User from "./pages/Users.js";
import Branch from "./pages/Branches";
import Customer from "./pages/Customer";
import Visitor from "./pages/Visitors";
import Event from "./pages/Events";
import CustomerView from "./pages/CustomerView";
import Service from "./pages/Service";
import Payment from "./pages/Payment";
import Celebration from "./pages/Celebration.js";

const AppBody = (props) => {
  return (
    <React.Fragment>
      <Banner />
      <div className="content-wrapper">
        <SideBar />
        <main className="admin-content-wrapper">
          <Outlet />
        </main>
      </div>
    </React.Fragment>
  );
};

const PrivateRoute = (props) => {
  return <AppBody {...props} />;
};

function App() {
  return (
    <Router>
      <Routes>
        <Route element={<PrivateRoute />}>
          <Route path="/dashboard" element={<Home />} />
          <Route path="/users" element={<User />} />
          <Route path="/branches" element={<Branch />} />
          <Route path="/services" element={<Service />} />
          <Route path="/visitors" element={<Visitor />} />
          <Route path="/members" element={<Customer />} />
          <Route path="/events" element={<Event />} />
          <Route path="/members/:id" element={<CustomerView />} />
          <Route path="/payments" element={<Payment />} />
          <Route path="/celebration" element={<Celebration />} />
        </Route>
        <Route exact path="/" element={<Login />} />
      </Routes>
    </Router>
  );
}

export default App;
