export const LOCAL_STORAGE_AUTH_KEY = "CLIENT_LOCAL_STORAGE_AUTH_KEY";
export const DATE_FORMAT = "DD/MM/YYYY";
export const DATE_FORMAT_WITH_TIME = "DD/MM/YYYY, h:mm:ss A";
export const TABLE_DEFAULT_LIMIT = 20;

export const SECTIONS = [
  {
    value: "Cake",
    label: "Cake",
  },
];

export const TYPE = [
  {
    value: "FREE",
    label: "Free",
  },
  {
    value: "PAID",
    label: "Paid",
  },
];

export const SERVICE_STATUS_TYPE = {
  PENDING: "PENDING",
  COMPLETED: "COMPLETED",
  OVERDUE: "OVERDUE",
};

export const SERVICESTATUS = [
  {
    value: SERVICE_STATUS_TYPE.PENDING,
    label: "Pending",
  },
  {
    value: SERVICE_STATUS_TYPE.COMPLETED,
    label: "Completed",
  },
];

export const BREAKDOWNSTATUS = [
  {
    value: "PENDING",
    label: "Pending",
  },
  {
    value: "REPAIRED",
    label: "Repaired",
  },
];

export const RESELL_SCRAP_TYPE = {
  SCRAP: "SCRAP",
  RESELL: "Resell",
};

export const REPORT_FOR = {
  MACHINE: "MACHINE",
  PURCHASE_ORDER: "PURCHASE_ORDER",
  INSTALLATION: "INSTALLATION",
  SERVICE: "SERVICE",
  BREAKDOWN: "BREAKDOWN",
  RESELL: "RESELL",
};

export const CURRENCY_CODE = {
  INR: "INR",
  IDR: "IDR",
  ILS: "ILS",
  MXN: "MXN",
  USD: "USD",
  ZAR: "ZAR",
  NZD: "NZD",
  NOK: "NOK",
  CNY: "CNY",
  BGN: "BGN",
  TRY: "TRY",
  PLN: "PLN",
  SGD: "SGD",
  CHF: "CHF",
  THB: "THB",
  JPY: "JPY",
  HRK: "HRK",
  RUB: "RUB",
  BRL: "BRL",
  CAD: "CAD",
  HKD: "HKD",
  ISK: "ISK",
  PHP: "PHP",
  DKK: "DKK",
  HUF: "HUF",
  CZK: "CZK",
  RON: "RON",
  SEK: "SEK",
  AUD: "AUD",
  EUR: "EUR",
  MYR: "MYR",
  KRW: "KRW",
  GBP: "GBP",
};

export const ROLE = {
  ADMIN: "admin",
  EMPLOYEE: "employee",
};

export const MACHINE_STATUS = {
  INITIATED: "INITIATED",
  APPROVED: "APPROVED",
  INSTALLED: "INSTALLED",
};

export const DEBOUNCE_DELAY = 500;
