import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { get_auth_key, storeData } from "../../util";
import { useDispatch } from "react-redux";
import { setUserLogin } from "../../store/reducers/account_reducer.js";

const Login = (props) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [userError, setUserError] = useState("");
  const [pwdError, setPwdError] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoggedIn, setLoggedIn] = useState(false);
  const userNameRef = useRef();
  const pwdRef = useRef();
  const navigate = useNavigate();

  let dispatch = useDispatch();

  const handleUsername = (e) => {
    if (e.target.value.trim()) {
      setUsername(e.target.value.trim());
    } else {
      setUsername("");
      setUserError("This field is required");
    }
  };

  const handlePassword = (e) => {
    if (e.target.value.trim()) {
      setPassword(e.target.value.trim());
    } else {
      setPassword("");
      setPwdError("This field is required");
    }
  };

  const handleLogin = () => {
    setErrorMessage("");
    let request = { username: username, password: password };
    axios.post(process.env.REACT_APP_BASE_URL + "/users/login", request).then((response) => {
      if (response.status === 200) {
        let data = (response.data && response.data.data) || null;
        if (data && data.auth) {
          storeData(get_auth_key(), JSON.stringify({ displayName: data.displayName, role: data.role, token: data.token, branchId: data.branchId }));
          setLoggedIn(true);
          dispatch(setUserLogin());
        }
      } else {
        handleErrorResponse(response);
      }
    }).catch((error) => {
      handleErrorResponse(error.response);
    });
  };

  const handleErrorResponse = (resp) => {
    if (resp) {
      setErrorMessage((resp.data && resp.data.data && resp.data.data.message) || resp.statusText);
    }
  };

  useEffect(() => {
    if (isLoggedIn) {
      navigate('/members');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn]);


  return (
    <div className="login-parent-wrapper">
      <div className="login-wrapper">
        <div className="login-logo">
          <img src={`${process.env.PUBLIC_URL}/gym500.png`} alt="logo" />
        </div>
        {(errorMessage && (
          <div className="alert alert-danger" role="alert">
            {errorMessage}
          </div>
        )) ||
          null}
        <div className="logo-field-wrapper">
          <div className="row">
            <div className="form-group col-12">
              <label className="form-label" htmlFor="username">
                Username
              </label>
              <input
                ref={userNameRef}
                type="text"
                name="username"
                id="username"
                value={username}
                placeholder="Username"
                onChange={handleUsername}
                className="form-control"
                required
                autoFocus
              />
              {userNameRef &&
                userNameRef.current &&
                userNameRef.current.touched &&
                userNameRef.current.invalid &&
                userError ? (
                <span className="err-message" title={userError}>
                  {userError}
                </span>
              ) : null}
            </div>
            <div className="form-group col-12">
              <label className="form-label" htmlFor="password">
                Password
              </label>
              <input
                ref={pwdRef}
                type="password"
                name="password"
                id="password"
                value={password}
                placeholder="Password"
                onChange={handlePassword}
                className="form-control"
                required
              />
              {pwdRef &&
                pwdRef.current &&
                pwdRef.current.touched &&
                pwdRef.current.invalid &&
                pwdError ? (
                <span className="err-message" title={pwdError}>
                  {pwdError}
                </span>
              ) : null}
            </div>
            <div className="col-12 mt-2">
              <button
                className="btn app-btn-primary app-full"
                type="button"
                onClick={handleLogin}
              >
                Login
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
