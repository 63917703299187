import React, { useState, useEffect, useRef } from "react";
import { get, post, put } from "../services/apiController";
import { TABLE_DEFAULT_LIMIT } from "../util/constant";
import {
  Table,
  Modal,
  Button,
  Form,
  FloatingLabel,
  Row,
  Col,
} from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";
import { DeleteIcon, EditIcon } from "./icons";
import { Link } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import PaginationInPage from "./Pagination";
import DeleteModal from "../components/modals/DeleteModal";
import moment from "moment";
import Branch from "../components/Branch";
import CreatableSelect from "react-select/creatable";
import { GrDocumentExcel } from "react-icons/gr";

const Visitors = () => {
  const formFields = {
    name: "",
    email: "",
    phone: "",
    branchId: "",
    dob: "",
    address: "",
    visitingDate: "",
    reference: "",
    handledBy: "",
    gender: "",
    purpose: "",
    occupation: "",
    interestedProgram: "",
    serviceDuration: "",
    chest: "",
    biceps: "",
    waist: "",
    weight: "",
  };
  const clickToDownload = useRef(null);
  const [totalRecords, setTotalRecords] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [visitorForm, setVisitorForm] = useState(formFields);
  const [showConfirmModel, setShowConfirmModel] = useState(false);
  const [idToDeleted, setIdToDeleted] = useState("");
  const [deleteModal, setDeleteModal] = useState(false);
  const [visitorList, setVisitorList] = useState([]);

  const handleShow = () => {
    setShowConfirmModel(true);
    setVisitorForm(formFields);
  };

  const handleClose = () => {
    setShowConfirmModel(false);
  };

  const handleEdit = (e, id) => {
    e.preventDefault();
    const visitor = visitorList.find((item) => item.id === +id);
    if (visitor) {
      setVisitorForm({
        ...visitor,
        reference: JSON.parse(visitor.reference),
        purpose: JSON.parse(visitor.purpose),
        occupation: JSON.parse(visitor.occupation),
        interestedProgram: JSON.parse(visitor.interestedProgram),
      });
      setShowConfirmModel(true);
    }
  };

  const handleChange = (visitor) => {
    visitor.preventDefault();
    const { name, value } = visitor.target;
    setVisitorForm({ ...visitorForm, [name]: value });
  };

  const handleConfirmDeleteModal = (e, id) => {
    e.preventDefault();
    setDeleteModal(true);
    setIdToDeleted(id);
  };

  const handleSubmit = (visitor) => {
    visitor.preventDefault();
    if ("id" in visitorForm) {
      try {
        put(visitorForm, "/visitors/" + visitorForm.id)
          .then((response) => {
            toast.success("Visitor updated successfully");
            setShowConfirmModel(false);
            FetchVisitors();
          })
          .catch((err) => {
            toast.error(
              err?.response?.data?.data?.message || err?.response?.data?.message
            );
          });
      } catch (err) {
        toast.error(err?.response?.data?.message || err?.message, {
          autoClose: 3000,
        });
      }
    } else {
      try {
        post(visitorForm, "/visitors/")
          .then((response) => {
            toast.success("Visitor added successfully");
            setShowConfirmModel(false);
            FetchVisitors();
          })
          .catch((err) => {
            toast.error(
              err?.response?.data?.data?.message || err?.response?.data?.message
            );
          });
      } catch (err) {
        toast.error(err?.response?.data?.message || err?.message, {
          autoClose: 2000,
        });
      }
    }
  };

  const FetchVisitors = (conditions) => {
    try {
      let url = "";
      if (conditions === "excel") {
        url = `/visitors/?conditionType=download`;
      } else {
        url = `/visitors/?page=${currentPage}&limit=${TABLE_DEFAULT_LIMIT}`;
      }
      get(url).then((response) => {
        if (conditions === "excel") {
          clickToDownload.current.href = `${process.env.REACT_APP_BASE_URL}/file/download?filePath=${response.data.filePath}`;
          setTimeout(() => {
            clickToDownload.current.click();
          }, 500);
        } else {
          setVisitorList(response.data.rows);
          setTotalRecords(response.data.count);
        }
      });
    } catch (err) {
      toast.error(err.response.data.data.message);
    }
  };

  useEffect(() => {
    FetchVisitors();
  }, [currentPage]);

  return (
    <React.Fragment>
      <ToastContainer />
      <div className="card">
        <div
          className="card-header white-color"
          style={{
            display: "flex",
            justifyContent: "flex-end",
            padding: "1rem 1.7rem",
          }}
        >
          <h4 className="card-title mb-0" style={{ fontSize: "16px" }}>
            Visitors | {totalRecords ?? 0}
          </h4>
          <button
            type="button"
            className="btn app-btn-default dark-color "
            onClick={handleShow}
          >
            Add New Visitor
          </button>
          <a
            ref={clickToDownload}
            href="/"
            target="_blank"
            className="hiddenItem"
          >
            HiddenClick
          </a>
          <GrDocumentExcel
            onClick={() => FetchVisitors("excel")}
            style={{ width: "70px", height: "30px", cursor: "pointer" }}
            title="Download Excel report"
            className="react-icons green"
          />
        </div>
        <div className="card-body px-0 vehicle-requests-table pt-0 pb-0">
          <div className="row">
            <div className="col">
              <Table responsive striped>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Phone</th>
                    <th>Branch</th>
                    <th>Visiting Date</th>
                    <th>Handled By</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {visitorList.map((item, index) => {
                    return (
                      <tr key={item?.id}>
                        <td>
                          {(currentPage - 1) * TABLE_DEFAULT_LIMIT +
                            (index + 1)}
                        </td>
                        <td>{item?.name}</td>
                        <td>{item?.email}</td>
                        <td>{item?.phone}</td>
                        <td>{item?.Branch?.name}</td>
                        <td>
                          {moment(item?.visitingDate).format("DD/MM/YYYY")}
                        </td>
                        <td>{item?.handledBy}</td>
                        <td className="actions">
                          <span
                            onClick={(e) => handleEdit(e, item?.id)}
                            className="edit-icon"
                          >
                            <EditIcon />
                          </span>
                          <Link
                            onClick={(e) =>
                              handleConfirmDeleteModal(e, item?.id)
                            }
                            className="delete"
                            to="/"
                          >
                            <DeleteIcon />
                          </Link>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
              <PaginationInPage
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                totalRecords={totalRecords}
                TABLE_DEFAULT_LIMIT={TABLE_DEFAULT_LIMIT}
              />
            </div>
          </div>
        </div>
      </div>
      <Modal
        size="lg"
        show={showConfirmModel}
        backdrop="static"
        keyboard={false}
        className="request-modal"
      >
        <Modal.Header closeButton onClick={handleClose}>
          <Modal.Title>
            {"id" in visitorForm ? "Edit Visitor" : "Add Visitor"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form autoComplete="off">
            <Row>
              <Col xs={4}>
                <FloatingLabel
                  controlId="floatingInput"
                  label="Visitor Name"
                  className="mb-3"
                >
                  <Form.Control
                    className="input"
                    value={visitorForm.name}
                    name="name"
                    onChange={handleChange}
                    type="text"
                    placeholder="Visitor Name"
                    required
                  />
                </FloatingLabel>
              </Col>
              <Col xs={4}>
                <FloatingLabel
                  controlId="floatingInput"
                  label="Email"
                  className="mb-3"
                >
                  <Form.Control
                    className="input"
                    value={visitorForm.email}
                    name="email"
                    onChange={handleChange}
                    type="email"
                    placeholder="Email"
                    required
                  />
                </FloatingLabel>
              </Col>{" "}
              <Col xs={4}>
                <FloatingLabel
                  controlId="floatingInputPhone"
                  label="Phone"
                  className="mb-3"
                >
                  <Form.Control
                    className="input"
                    value={visitorForm.phone}
                    name="phone"
                    onChange={handleChange}
                    type="tel"
                    placeholder="Phone"
                    required
                  />
                </FloatingLabel>
              </Col>
              <Col xs={4}>
                <FloatingLabel
                  controlId="floatingInput"
                  label="Branch's Name"
                  className="mb-3"
                >
                  <Branch
                    branchId={visitorForm.branchId}
                    onChange={handleChange}
                    name="branchId"
                    disabled={"id" in visitorForm}
                    required={true}
                  />
                </FloatingLabel>
              </Col>
              <Col xs={4}>
                <FloatingLabel
                  controlId="floatingInputDob"
                  label="Date of Birth"
                  className="mb-3"
                >
                  <Form.Control
                    className="input"
                    value={visitorForm.dob}
                    name="dob"
                    onChange={handleChange}
                    type="date"
                    placeholder="Date of Birth"
                    required
                  />
                </FloatingLabel>
              </Col>
              <Col xs={4}>
                <FloatingLabel
                  controlId="floatingInputAddress"
                  label="Address"
                  className="mb-3"
                >
                  <Form.Control
                    className="input"
                    value={visitorForm.address}
                    name="address"
                    onChange={handleChange}
                    type="text"
                    placeholder="Address"
                    required
                  />
                </FloatingLabel>
              </Col>
              <Col xs={4}>
                <FloatingLabel
                  controlId="floatingInputVisitingDate"
                  label="Visiting Date"
                  className="mb-3"
                >
                  <Form.Control
                    className="input"
                    value={visitorForm.visitingDate}
                    name="visitingDate"
                    onChange={handleChange}
                    type="date"
                    placeholder="Visiting Date"
                    required
                  />
                </FloatingLabel>
              </Col>
              <Col xs={4}>
                <CreatableSelect
                  name="reference"
                  className="mb-3"
                  placeholder="Select Reference"
                  value={visitorForm.reference}
                  isClearable
                  isSearchable
                  options={[
                    { label: "Social Media", value: "social_media" },
                    { label: "Friends/Collegues", value: "friends" },
                    { label: "Trainers", value: "trainers" },
                    { label: "Gym Members", value: "members" },
                    { label: "Other", value: "other" },
                  ]}
                  onChange={(value) =>
                    setVisitorForm({ ...visitorForm, reference: value })
                  }
                  styles={{
                    control: (provided, state) => ({
                      ...provided,
                      minHeight: "55px",
                    }),
                  }}
                />
              </Col>
              <Col xs={4}>
                <FloatingLabel
                  controlId="floatingInputHandledBy"
                  label="Handled By"
                  className="mb-3"
                >
                  <Form.Control
                    className="input"
                    value={visitorForm.handledBy}
                    name="handledBy"
                    onChange={handleChange}
                    type="text"
                    placeholder="Handled By"
                  />
                </FloatingLabel>
              </Col>
              <Col xs={4}>
                <FloatingLabel
                  controlId="floatingInput"
                  label="Gender"
                  className="mb-3"
                >
                  <select
                    name="gender"
                    value={visitorForm.gender}
                    onChange={handleChange}
                    aria-label="Floating label select example"
                    className="form-select"
                  >
                    <option value="">Select</option>
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                  </select>
                </FloatingLabel>
              </Col>
              <Col xs={4}>
                <CreatableSelect
                  name="purpose"
                  className="mb-3"
                  placeholder="Select Purpose"
                  value={visitorForm.purpose}
                  isClearable
                  isSearchable
                  options={[
                    { label: "Weight Loss", value: "weight_loss" },
                    { label: "Weight Gain", value: "weight_gain" },
                    { label: "Strength Training", value: "strength_training" },
                    { label: "Any specific - goal", value: "goal" },
                    { label: "Other", value: "other" },
                  ]}
                  onChange={(value) =>
                    setVisitorForm({ ...visitorForm, purpose: value })
                  }
                  styles={{
                    control: (provided, state) => ({
                      ...provided,
                      minHeight: "55px",
                    }),
                  }}
                />
              </Col>
              <Col xs={4}>
                <CreatableSelect
                  name="occupation"
                  className="mb-3"
                  placeholder="Select Occupation"
                  value={visitorForm.occupation}
                  isClearable
                  isSearchable
                  options={[
                    { label: "Working", value: "working" },
                    { label: "Self Employed", value: "self_employed" },
                    { label: "Studying", value: "studying" },
                    { label: "Other", value: "other" },
                  ]}
                  onChange={(value) =>
                    setVisitorForm({ ...visitorForm, occupation: value })
                  }
                  styles={{
                    control: (provided, state) => ({
                      ...provided,
                      minHeight: "55px",
                    }),
                  }}
                />
              </Col>
              <Col xs={4}>
                <FloatingLabel
                  controlId="floatingInput"
                  label="Service's Duration"
                  className="mb-3"
                >
                  <select
                    name="serviceDuration"
                    value={visitorForm.serviceDuration}
                    onChange={handleChange}
                    aria-label="Floating label select example"
                    className="form-select"
                  >
                    <option value="">Select Duration</option>
                    <option value="monthly">1 Month</option>
                    <option value="quarterly">3 Months</option>
                    <option value="half_yearly">6 Months</option>
                    <option value="yearly">12 Months</option>
                  </select>
                </FloatingLabel>
              </Col>
              <Col xs={4}>
                <FloatingLabel
                  controlId="floatingInputChest"
                  label="Chest"
                  className="mb-3"
                >
                  <Form.Control
                    className="input"
                    value={visitorForm.chest}
                    name="chest"
                    onChange={handleChange}
                    type="text"
                    placeholder="Chest"
                  />
                </FloatingLabel>
              </Col>
              <Col xs={4}>
                <FloatingLabel
                  controlId="floatingInputBiceps"
                  label="Biceps"
                  className="mb-3"
                >
                  <Form.Control
                    className="input"
                    value={visitorForm.biceps}
                    name="biceps"
                    onChange={handleChange}
                    type="text"
                    placeholder="Biceps"
                  />
                </FloatingLabel>
              </Col>
              <Col xs={4}>
                <FloatingLabel
                  controlId="floatingInputWaist"
                  label="Waist"
                  className="mb-3"
                >
                  <Form.Control
                    className="input"
                    value={visitorForm.waist}
                    name="waist"
                    onChange={handleChange}
                    type="text"
                    placeholder="Waist"
                  />
                </FloatingLabel>
              </Col>
              <Col xs={4}>
                <FloatingLabel
                  controlId="floatingInputWeight"
                  label="Weight"
                  className="mb-3"
                >
                  <Form.Control
                    className="input"
                    value={visitorForm.weight}
                    name="weight"
                    onChange={handleChange}
                    type="text"
                    placeholder="Weight"
                  />
                </FloatingLabel>
              </Col>
              <Col xs={12}>
                <Form.Label>Interested Program</Form.Label>
                <CreatableSelect
                  name="interestedProgram"
                  className="mb-3"
                  placeholder="Select interested programs"
                  value={visitorForm.interestedProgram}
                  isMulti
                  isClearable
                  isSearchable
                  options={[
                    { label: "Zumba", value: "zumba" },
                    { label: "Yoga", value: "yoga" },
                    { label: "Cricket", value: "Cricket" },
                    { label: "Martial Arts", value: "Martial Arts" },
                    { label: "Trekking", value: "Trekking" },
                    { label: "Biking", value: "Biking" },
                  ]}
                  onChange={(value) =>
                    setVisitorForm({ ...visitorForm, interestedProgram: value })
                  }
                />
              </Col>
            </Row>
            <div className="container text-center">
              <Button
                type="submit"
                onClick={handleSubmit}
                className="add-request-btn mt-4 mb-3"
              >
                {"id" in visitorForm ? "Update" : "Add"}
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
      {deleteModal && (
        <DeleteModal
          title={"Delete Visitor"}
          description={"Are you sure? Action cannot be undone."}
          deleteModal={deleteModal}
          setDeleteModal={setDeleteModal}
          idToDeleted={idToDeleted}
          url={"/visitors"}
          callback={() => FetchVisitors()}
        />
      )}
    </React.Fragment>
  );
};

export default Visitors;
