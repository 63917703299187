import React, { useState, useEffect, useRef } from "react";
import { get } from "../services/apiController";
import { TABLE_DEFAULT_LIMIT } from "../util/constant";
import { Table, Form, FloatingLabel, Col, Tabs, Tab } from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";
import { Link } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import PaginationInPage from "./Pagination";
import moment from "moment";
import { GrDocumentExcel } from "react-icons/gr";
import { ViewIcon } from "./icons";
import CustomerViewModal from "../components/modals/CustomerView";

const Visitors = () => {
  const [totalRecords, setTotalRecords] = useState(0);
  const [totalAnniversaryRecords, setTotalAnniversaryRecords] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentPageAnniversary, setCurrentPageAnniversary] = useState(1);
  const [userBirthList, setuserBirthList] = useState([]);
  const [userAnniversaryList, setuserAnniversaryList] = useState([]);
  const clickToDownload = useRef(null);
  const [showViewModal, setShowViewModal] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [state, setState] = useState({
    startDate: "",
    endDate: "",
  });
  const [activePage, setActivePage] = useState("birthday");
  // Get the current year
  const currentYear = new Date().getFullYear();

  // Calculate the next year
  const nextYear = currentYear + 1;

  // Construct the minimum and maximum dates for the current and next year
  const minDate = `${currentYear}-01-01`; // Minimum date for the current year
  const maxDate = `${nextYear}-12-31`; // Maximum date for the next year

  const handleView = (customer) => {
    setSelectedCustomer(customer);
    setShowViewModal(true);
  };

  // Function to handle closing the view modal
  const handleCloseViewModal = () => {
    setSelectedCustomer(null);
    setShowViewModal(false);
  };

  const getCustomers = async (conditions) => {
    try {
      let url = "";
      if (conditions === "excel") {
        url = `/customers/get-birthday/?conditionType=download&type=${activePage}&startDate=${state.startDate}&endDate=${state.endDate}`;
      } else {
        url = `/customers/get-birthday/?page=${currentPage}&limit=${TABLE_DEFAULT_LIMIT}&startDate=${state.startDate}&endDate=${state.endDate}`;
      }
      get(url).then((response) => {
        if (conditions !== "excel") {
          setuserBirthList(response?.data?.users?.rows);
          setTotalRecords(response?.data?.users?.count);
        } else {
          clickToDownload.current.href = `${process.env.REACT_APP_BASE_URL}/file/download?filePath=${response.data.filePath}`;
          setTimeout(() => {
            clickToDownload.current.click();
          }, 500);
        }
      });
    } catch (err) {
      toast.error(err.response.data.data.message);
    }
  };

  const getAnniversary = async (conditions) => {
    try {
      let url = "";
      if (conditions === "excel") {
        url = `/customers/get-anniversary/?conditionType=download&type=${activePage}&startDate=${state.startDate}&endDate=${state.endDate}`;
      } else {
        url = `/customers/get-anniversary/?page=${currentPageAnniversary}&limit=${TABLE_DEFAULT_LIMIT}&startDate=${state.startDate}&endDate=${state.endDate}`;
      }
      get(url).then((response) => {
        if (conditions !== "excel") {
          setuserAnniversaryList(response?.data?.anniversary?.rows);
          setTotalAnniversaryRecords(response?.data?.anniversary?.count);
        } else {
          clickToDownload.current.href = `${process.env.REACT_APP_BASE_URL}/file/download?filePath=${response.data.filePath}`;
          setTimeout(() => {
            clickToDownload.current.click();
          }, 500);
        }
      });
    } catch (err) {
      toast.error(err.response.data.data.message);
    }
  };

  const getHandelCallExcel = async () => {
    try {
      if (activePage === "anniversary") {
        await getAnniversary("excel");
      } else {
        await getCustomers("excel");
      }
    } catch (err) {
      toast.error(err?.response?.data?.data?.message);
    }
  };

  const getHandelCallFilter = async () => {
    try {
      if (activePage === "anniversary") {
        await getAnniversary();
      } else {
        await getCustomers();
      }
    } catch (err) {
      toast.error(err?.response?.data?.data?.message);
    }
  };

  useEffect(() => {
    getCustomers();
  }, [currentPage]);

  useEffect(() => {
    getAnniversary();
  }, [currentPageAnniversary]);

  return (
    <React.Fragment>
      <ToastContainer />
      <div className="card">
        <div
          className="card-header white-color"
          style={{
            display: "flex",
            justifyContent: "flex-end",
            padding: "1rem 1.7rem",
          }}
        >
          {activePage === "birthday" ? (
            <h4 className="card-title mb-0" style={{ fontSize: "16px" }}>
              Birthday | {totalRecords ?? 0}
            </h4>
          ) : (
            <h4 className="card-title mb-0" style={{ fontSize: "16px" }}>
              Anniversary | {totalAnniversaryRecords ?? 0}
            </h4>
          )}
          <Col xs={2} className="mx-2">
            <FloatingLabel controlId="floatingInput" label="Start Date">
              <Form.Control
                value={state.startDate}
                onChange={(e) =>
                  setState({ ...state, startDate: e.target.value })
                }
                type="date"
                min={minDate} // Set the minimum date to the start of the current year
                max={maxDate} // Set the maximum date to the end of the next year
              />
            </FloatingLabel>
          </Col>
          <Col xs={2} className="mx-2">
            <FloatingLabel controlId="floatingInput" label="End Date">
              <Form.Control
                value={state.endDate}
                onChange={(e) =>
                  setState({ ...state, endDate: e.target.value })
                }
                type="date"
                min={minDate} // Set the minimum date to the start of the current year
                max={maxDate} // Set the maximum date to the end of the next year
              />
            </FloatingLabel>
          </Col>
          <Col xs={2} className="mx-2">
            <button
              type="button"
              className="btn app-btn-default dark-color  "
              onClick={getHandelCallFilter}
              style={{ width: "150px", height: "50px" }}
            >
              Fetch Data
            </button>
          </Col>
          <a
            ref={clickToDownload}
            href="/"
            target="_blank"
            className="hiddenItem"
          >
            HiddenClick
          </a>
          <GrDocumentExcel
            onClick={() => getHandelCallExcel()}
            style={{ width: "70px", height: "30px", cursor: "pointer" }}
            title="Download Excel report"
            className="react-icons green"
          />
        </div>
        <Tabs
          defaultActiveKey="birthday"
          id="uncontrolled-tab-example"
          className="mb-3"
          onSelect={(k) => setActivePage(k)}
        >
          <Tab eventKey="birthday" title="Birthday">
            <div className="card-body px-0 vehicle-requests-table pt-0 pb-0">
              <div className="row">
                <div className="col">
                  <Table responsive striped>
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Name</th>
                        <th>Birthday</th>
                        <th>Email</th>
                        <th>Phone No.</th>
                        <th>Address</th>
                        <th>Branch Name</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {userBirthList.map((item, index) => {
                        const formattedBirthday = moment(item.dob).format(
                          "MMMM Do"
                        );
                        return (
                          <tr key={item?.id}>
                            <td>
                              {(currentPage - 1) * TABLE_DEFAULT_LIMIT +
                                (index + 1)}
                            </td>
                            <td>{item.name}</td>
                            <td>Birthday on {formattedBirthday}</td>
                            <td>{item.email}</td>
                            <td>{item.phone}</td>
                            <td>{item.address}</td>
                            <td>{item.Branch.name}</td>
                            <td className="actions">
                              <Link onClick={() => handleView(item)}>
                                <ViewIcon />
                              </Link>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                  <PaginationInPage
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    totalRecords={totalRecords}
                    TABLE_DEFAULT_LIMIT={TABLE_DEFAULT_LIMIT}
                  />
                </div>
              </div>
            </div>
          </Tab>
          <Tab eventKey="anniversary" title="Anniversary">
            <div className="card-body px-0 vehicle-requests-table pt-0 pb-0">
              <div className="row">
                <div className="col">
                  <Table responsive striped>
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Name</th>
                        <th>Anniversary</th>
                        <th>Email</th>
                        <th>Phone No.</th>
                        <th>Address</th>
                        <th>Branch Name</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {userAnniversaryList.map((item, index) => {
                        const formattedBirthday = moment(
                          item.anniversary
                        ).format("MMMM Do");

                        return (
                          <tr key={item?.id}>
                            <td>
                              {(currentPageAnniversary - 1) *
                                TABLE_DEFAULT_LIMIT +
                                (index + 1)}
                            </td>
                            <td>{item.name}</td>
                            <td>Anniversary on {formattedBirthday}</td>
                            <td>{item.email}</td>
                            <td>{item.phone}</td>
                            <td>{item.address}</td>
                            <td>{item.Branch.name}</td>
                            <td className="actions">
                              <Link onClick={() => handleView(item)}>
                                <ViewIcon />
                              </Link>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                  <PaginationInPage
                    currentPage={currentPageAnniversary}
                    setCurrentPage={setCurrentPageAnniversary}
                    totalRecords={totalAnniversaryRecords}
                    TABLE_DEFAULT_LIMIT={TABLE_DEFAULT_LIMIT}
                  />
                </div>
              </div>
            </div>
          </Tab>
        </Tabs>
      </div>
      <CustomerViewModal
        showViewModal={showViewModal}
        selectedCustomer={selectedCustomer}
        handleCloseViewModal={handleCloseViewModal}
      />
    </React.Fragment>
  );
};

export default Visitors;
