import { LOCAL_STORAGE_AUTH_KEY, ROLE } from "./constant.js";
export const get_auth_key = () => {
  return window.btoa(LOCAL_STORAGE_AUTH_KEY);
};

export const storeData = (key, value) => {
  localStorage.setItem(key, window.btoa(value));
};

export const getStoredData = (key) => {
  if (localStorage.getItem(key)) {
    return window.atob(localStorage.getItem(key));
  } else {
    return null;
  }
};

export const deleteStoredData = (key) => {
  if (localStorage.getItem(key)) {
    localStorage.removeItem(key);
  }
  return true;
};

export const validateEmail = (mail) => {
  if (
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
      mail
    )
  ) {
    return true;
  }
  return false;
};

export const encrypt = (value) => {
  return window.btoa(value);
};

export const isAdmin = () => {
  let flag = false;
  try {
    let udata = JSON.parse(getStoredData(get_auth_key()));
    if (udata) {
      let role = (udata.role || "").toLowerCase();
      if (role === ROLE.ADMIN) {
        flag = true;
      }
    }
  } catch (e) {
    flag = false;
  }
  return flag;
};
