import React, { useState, useEffect } from "react";
import { Row } from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";
import { Link, useParams } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { get } from "../services/apiController";

const CustomerView = () => {
  let { id } = useParams();
  const [customerDetail, setCustomerDetail] = useState("");

  function getCustomersDetail() {
    try {
      get(`/customers/${id}`)
        .then((response) => {
          setCustomerDetail(response.data);
        })
        .catch((err) => {
          toast.error(err?.response?.data?.data?.message);
        });
    } catch (e) {
      return e;
    }
  }

  useEffect(() => {
    getCustomersDetail();
  }, []);

  return (
    <React.Fragment>
      <ToastContainer />
      <nav className="" aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/members">Customer List</Link>
          </li>
          <li className="breadcrumb-item">
            <Link to={`/members/${customerDetail?.id}`}>Customer Detail</Link>
          </li>
        </ol>
      </nav>
      <div className="card">
        <div
          className="card-header white-color"
          style={{ padding: "1rem 1.7rem" }}
        >
          <h4 className="card-title mb-0" style={{ fontSize: "16px" }}>
            Customer Detail
          </h4>
        </div>
        <div className="card-body">
          <Row className="top-row">
            <div className="col-12 col-lg-3">
              <div className="col-md-4 mb-5">
                <img
                  style={{
                    maxWidth: "200px",
                    maxHeight: "400px",
                    width: "auto",
                    height: "auto",
                  }}
                  src={
                    customerDetail?.image !== null
                      ? `${customerDetail?.image}`
                      : `${process.env.PUBLIC_URL}/no-image.png`
                  }
                  alt="Member"
                />
              </div>
            </div>
            <div className="col-12 col-lg-9">
              <Row className="top-row">
                <div className="col-md-4">
                  <dd className="display-title mb-1">Id</dd>
                  <dt className="display-value">{customerDetail?.id}</dt>
                </div>
                <div className="col-md-4">
                  <dd className="display-title mb-1">Name</dd>
                  <dt className="display-value">{customerDetail?.name}</dt>
                </div>
                <div className="col-md-4">
                  <dd className="display-title mb-1">Email</dd>
                  <dt className="display-value">{customerDetail?.email}</dt>
                </div>
                <div className="col-md-4">
                  <dd className="display-title mb-1">Branch Name</dd>
                  <dt className="display-value">
                    {customerDetail?.Branch?.name}
                  </dt>
                </div>
                <div className="col-md-4">
                  <dd className="display-title mb-1">Mobile No.</dd>
                  <dt className="display-value">{customerDetail?.phone}</dt>
                </div>

                <div className="col-md-4">
                  <dd className="display-title mb-1">D.O.B.</dd>
                  <dt className="display-value">{customerDetail?.dob}</dt>
                </div>
                <div className="col-md-4">
                  <dd className="display-title mb-1">Age</dd>
                  <dt className="display-value">{customerDetail?.age}</dt>
                </div>
                <div className="col-md-4">
                  <dd className="display-title mb-1">Address</dd>
                  <dt className="display-value">{customerDetail?.address}</dt>
                </div>
                <div className="col-md-4">
                  <dd className="display-title mb-1">Gender</dd>
                  <dt className="display-value">
                    {customerDetail?.gender === "male"
                      ? "Male"
                      : customerDetail?.gender === "female"
                      ? "Female"
                      : null}
                  </dt>
                </div>
                <div className="col-md-4">
                  <dd className="display-title mb-1">Occupation</dd>
                  <dt className="display-value">
                    {customerDetail?.occupation}
                  </dt>
                </div>
                <div className="col-md-4">
                  <dd className="display-title mb-1">Measurement</dd>
                  <dt className="display-value">
                    Weight: {customerDetail?.weight}, Chest:{" "}
                    {customerDetail?.chest}, Biceps: {customerDetail?.biceps},
                    Waist: {customerDetail?.waist}
                  </dt>
                </div>
                <div className="col-md-4">
                  <dd className="display-title mb-1">Admission Date</dd>
                  <dt className="display-value">
                    {customerDetail?.admissionDate}
                  </dt>
                </div>
                <div className="col-md-4">
                  <dd className="display-title mb-1">Service</dd>
                  <dt className="display-value">
                    {customerDetail?.Service?.name}
                  </dt>
                </div>
                <div className="col-md-4">
                  <dd className="display-title mb-1">Duration</dd>
                  <dt className="display-value">
                    {customerDetail?.serviceDuration === "yearly"
                      ? "12 Month"
                      : customerDetail?.serviceDuration === "half_yearly"
                      ? "6 Month"
                      : customerDetail?.serviceDuration === "quarterly"
                      ? "3 Month"
                      : customerDetail?.serviceDuration === "monthly"
                      ? "1 Month"
                      : null}
                  </dt>
                </div>
                <div className="col-md-4">
                  <dd className="display-title mb-1">Amount</dd>
                  <dt className="display-value">{customerDetail.amount}</dt>
                </div>
                <div className="col-md-4">
                  <dd className="display-title mb-1">Next Due Date</dd>
                  <dt className="display-value">{customerDetail.due_date}</dt>
                </div>
              </Row>
            </div>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

export default CustomerView;
