import { createSlice } from "@reduxjs/toolkit";

export const menu = createSlice({
  name: "menu",
  initialState: {
    bannerText: "My home",
    isMenuOpen: false,
    breadCrumb: [],
  },
  reducers: {
    setBannerText: (state, action) => {
      state.bannerText = action.payload;
    },
    toggleMenu: (state, action) => {
      state.isMenuOpen = !state.isMenuOpen;
    },
    setBreadCrumb: (state, action) => {
      state.breadCrumb = [...state.breadCrumb, action.payload];
    },
    removeBreadCrumb: (state, action) => {
      state.breadCrumb = state.breadCrumb.slice(0, action.payload);
    },
    removeAllBreadCrumb: (state, action) => {
      state.breadCrumb = [];
    },
  },
});

export const {
  setBannerText,
  toggleMenu,
  setBreadCrumb,
  removeBreadCrumb,
  removeAllBreadCrumb,
} = menu.actions;
export const bannerText = (state) => state.menu.bannerText;
export const isMenuOpen = (state) => state.menu.isMenuOpen;
export const breadCrumb = (state) => state.menu.breadCrumb;
export default menu.reducer;
