import moment from "moment";
import { useEffect, useCallback } from "react";
import { DATE_FORMAT, DATE_FORMAT_WITH_TIME } from "./constant.js";

export const formatDate = (date, includeTime = false) => {
  if (date) {
    try {
      if (includeTime) {
        return moment(date).format(
          DATE_FORMAT_WITH_TIME || "DD/MM/YYYY, h:mm:ss A"
        );
      } else {
        return moment(date).format(DATE_FORMAT || "DD/MM/YYYY");
      }
    } catch (e) {
      console.error(e);
      return "";
    }
  } else {
    return "";
  }
};

export default function useDebounce(effect, dependencies, delay) {
  const callback = useCallback(effect, dependencies);
  useEffect(() => {
    const timeout = setTimeout(callback, delay);
    return () => clearTimeout(timeout);
  }, [callback, delay]);
}
