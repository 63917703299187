import React from "react";
import AlertIcon from "../../../src/img.svg";
import { destroy } from "../../services/apiController";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";

const DeleteModal = (props) => {
  const {
    title,
    description,
    deleteModal,
    setDeleteModal,
    idToDeleted,
    url,
    callback,
  } = props;

  const handleRemove = (event, id) => {
    event.preventDefault();
    destroy(`${url}/${id}`)
      .then((response) => {
        if (response.status === 200) {
          toast.success(
            response?.data?.message || "Record has been deleted successfully"
          );
          setDeleteModal(false);
          callback();
        }
      })
      .catch((error) => {
        if (error.response && error.response.data) {
          if (
            error?.response?.data?.data?.message.startsWith(
              "Cannot delete or update a parent row: a foreign key"
            )
          ) {
            toast.error(`Delete all associated data before deleting this.`);
          } else {
            toast.error(
              error.response.data.data.message ||
                error?.message ||
                "Something went wrong while deleting."
            );
          }
        } else {
          toast.error("Something went wrong.");
        }
      });
  };

  return (
    <Modal
      size="sm"
      show={deleteModal}
      backdrop="static"
      keyboard={false}
      className="request-modal"
    >
      <Modal.Header className="bg-danger text-light py-2">
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex justify-content-start">
          <img src={AlertIcon} alt="alert" />
          <p className="ps-3 mb-0">{description}</p>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="btn-wrapper">
          <button
            onClick={() => setDeleteModal(false)}
            className="btn app-btn-default btn-sm"
          >
            Cancel
          </button>
          <button
            onClick={(e) => handleRemove(e, idToDeleted)}
            className="btn app-btn-danger btn-sm"
          >
            Delete
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteModal;
