import React, { useEffect, useState, useRef } from "react";
import { destroy, get, post } from "../services/apiController";
import { TABLE_DEFAULT_LIMIT, DEBOUNCE_DELAY } from "../util/constant";
import {
  Button,
  Col,
  FloatingLabel,
  Form,
  Modal,
  Row,
  Table,
} from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";
import { DeleteIcon } from "./icons";
import { Link } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import AlertIcon from "../../src/img.svg";
import PaginationInPage from "./Pagination";
import useDebounce from "../util/helper";
import { GrDocumentExcel } from "react-icons/gr";
import { SlRefresh } from "react-icons/sl";
import Select from "react-select";

const Payment = () => {
  const formFields = {
    branchId: "",
    customerId: "",
    serviceId: "",
    amount: "",
    serviceStartDate: "",
  };
  const [totalRecords, setTotalRecords] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [paymentForm, setPaymentForm] = useState(formFields);
  const [showConfirmModel, setShowConfirmModel] = useState(false);
  const [idToDeleted, setIdToDeleted] = useState("");
  const [deleteModel, setDeleteModel] = useState(false);
  const [paymentList, setPaymentList] = useState([]);
  const [branchList, setBranchList] = useState([]);
  const [customerList, setCoustomerList] = useState([]);
  const [services, setServices] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [customerData, setCustomerData] = useState({});
  const [amounts, setAmounts] = useState({});

  const [state, setState] = useState({
    customerId: "",
    startDate: "",
    endDate: "",
  });
  const clickToDownload = useRef(null);
  const handleShow = () => {
    setShowConfirmModel(true);
    setPaymentForm(formFields);
  };

  const handleClose = () => {
    setShowConfirmModel(false);
  };

  const handleConfirmDeleteModal = (e, id) => {
    e.preventDefault();
    setDeleteModel(true);
    setIdToDeleted(id);
  };

  const handleRemove = (event, id) => {
    event.preventDefault();
    try {
      destroy("/payments/" + id).then((response) => {
        toast.success("Payment Record Deleted Successfully");
        setDeleteModel(false);
        getPayments();
      });
    } catch (err) {
      toast.error(err.message, { autoClose: 3000 });
    }
  };

  const loadBranchServicesAndCustomers = (branchId) => {
    get(`/customers/?branchId=${branchId}`).then((response) => {
      setCustomers(response?.data?.rows);
    });
    get(`/services/?branchId=${branchId}`).then((response) => {
      setServices(response?.data?.rows);
    });
  };

  const lastPlanDetail = (customerId) => {
    return customerList.find((customer) => {
      return customer.id === parseInt(customerId);
    });
  };

  const loadServiceAmounts = (serviceId) => {
    get(`/services/?id=${serviceId}`).then((res) => {
      setAmounts(res.data.rows[0]);
    });
  };

  const handleChange2 = (selectedOption) => {
    if (selectedOption) {
      let data = lastPlanDetail(selectedOption.value);
      setPaymentForm({
        ...paymentForm,
        customerId: selectedOption.value,
        serviceId: data.serviceId,
        serviceDuration: data.serviceDuration,
        amount: data.amount,
      });
      setCustomerData(data);
    } else {
      setPaymentForm({
        ...paymentForm,
        customerId: "",
        serviceId: "",
        serviceDuration: "",
        amount: "",
      });
      setCustomerData("");
    }
  };

  const handleChange = async (event) => {
    event.preventDefault();

    const { name, value } = event.target;
    let _paymentForm = { ...paymentForm, [name]: value };
    if (event.target.name === "branchId") {
      loadBranchServicesAndCustomers(value);
      _paymentForm = {
        ..._paymentForm,
        serviceDuration: "",
        amount: "",
        serviceStartDate: "",
      };
    } else if (event.target.name === "customerId" && value !== "") {
      let data = lastPlanDetail(value);
      _paymentForm = {
        ..._paymentForm,
        serviceStartDate: data.due_date,
        amount: data.amount,
        serviceDuration: data.serviceDuration,
        serviceId: data.serviceId,
      };
      setCustomerData(data);
    } else if (event.target.name === "serviceId") {
      setPaymentForm({ ..._paymentForm, serviceDuration: "", amount: "" });
      loadServiceAmounts(value);
    } else if (event.target.name === "serviceDuration") {
      let sub = event.target.value;
      setPaymentForm({ ..._paymentForm, amount: amounts[`${sub}`] });
    }
    if (
      event.target.name === "serviceId" ||
      event.target.name === "serviceDuration"
    ) {
      calculateAmount(_paymentForm);
    } else {
      setPaymentForm(_paymentForm);
    }
  };

  function calculateAmount(form) {
    const selectedService = services.find(
      (service) => +form.serviceId === service.id
    );
    setPaymentForm({ ...form, amount: selectedService[form.serviceDuration] });
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      await post({ ...paymentForm }, "/payments/");
      toast.success("Record saved successfully");
      setShowConfirmModel(false);
      getPayments();
    } catch (err) {
      toast.error(err?.response?.data?.data?.message, { autoClose: 2000 });
    }
  };

  const getPayments = (conditionType) => {
    try {
      if (conditionType === "download") {
        get(
          `/payments/?conditionType=${conditionType}&customerId=${state.customerId}&startDate=${state.startDate}&endDate=${state.endDate}`
        ).then((response) => {
          clickToDownload.current.href = `${process.env.REACT_APP_BASE_URL}/file/download?filePath=${response.data.filePath}`;
          setTimeout(() => {
            clickToDownload.current.click();
          }, 500);
        });
      } else {
        get(
          `/payments/?page=${currentPage}&limit=${TABLE_DEFAULT_LIMIT}&customerId=${state.customerId}&startDate=${state.startDate}&endDate=${state.endDate}`
        ).then((response) => {
          setPaymentList(response.data.rows);
          setTotalRecords(response.data.count);
        });
      }
    } catch (err) {
      toast.error(err?.response?.data?.data?.message);
    }
  };

  function getBranchDropdown() {
    get("/branches").then((response) => {
      setBranchList(response.data.rows);
    });
  }

  function getCustomerDropdown() {
    get("/customers").then((response) => {
      setCoustomerList(response.data.rows);
    });
  }

  useEffect(() => {
    getBranchDropdown();
    getCustomerDropdown();
  }, []);

  useEffect(() => {
    getPayments();
  }, [currentPage]);

  useDebounce(
    () => {
      if (currentPage !== 1) {
        setCurrentPage(1);
      } else {
        getPayments();
      }
    },
    [state],
    DEBOUNCE_DELAY
  );
  const handleSelectChange = (selectedOption) => {
    if (selectedOption) {
      setState({ ...state, customerId: selectedOption.value });
    } else {
      setState({ ...state, customerId: "" });
    }
  };

  return (
    <React.Fragment>
      <ToastContainer />
      <nav className="" aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/members">Fetch Members Payment Records</Link>
          </li>
        </ol>
      </nav>
      <div className="card top-card">
        <div className="card-body">
          <div className="col-12 blockLine my-3">
            <p className="my-0">
              <strong>Filter criteria</strong>
            </p>
            <hr className="my-0" />
          </div>
          <Row className="mb-3">
            <Col xs={2}>
              {/* <FloatingLabel controlId="floatingInput" label="Customer">
                <Form.Select className="input" onChange={(e) => setState({ ...state, customerId: e.target.value })} name="customerId" value={state.customerId} placeholder="Customer">
                  <option value=''>Select Member</option>
                  {customerList.sort((a, b) => a.name.localeCompare(b.name)).map(item => <option key={item?.id} value={item?.id}>{item?.name}</option>)}
                </Form.Select>
              </FloatingLabel> */}
              <Select
                onChange={handleSelectChange}
                className="basic-single"
                classNamePrefix="select"
                placeholder="Select Member"
                isSearchable
                isClearable
                name="customerId"
                options={customerList
                  .sort((a, b) => a.name.localeCompare(b.name))
                  .map((item) => ({ value: item?.id, label: item?.name }))}
                styles={{
                  control: (provided, state) => ({
                    ...provided,
                    minHeight: "55px", // Adjust the height value as needed
                  }),
                }}
              />
            </Col>
            <Col xs={2}>
              <FloatingLabel controlId="floatingInput" label="Start Date">
                <Form.Control
                  value={state.startDate}
                  onChange={(e) =>
                    setState({ ...state, startDate: e.target.value })
                  }
                  type="date"
                />
              </FloatingLabel>
            </Col>
            <Col xs={2}>
              <FloatingLabel controlId="floatingInput" label="End Date">
                <Form.Control
                  value={state.endDate}
                  onChange={(e) =>
                    setState({ ...state, endDate: e.target.value })
                  }
                  type="date"
                />
              </FloatingLabel>
            </Col>
            <Col xs={1} className="d-flex align-items-center">
              <SlRefresh
                className="text-primary"
                style={{ width: "70px", height: "30px", cursor: "pointer" }}
                onClick={() =>
                  setState({
                    ...state,
                    customerId: "",
                    startDate: "",
                    endDate: "",
                  })
                }
              />
            </Col>
            <Col
              xs={5}
              className="d-flex align-items-center justify-content-end"
              style={{ paddingRight: "0" }}
            >
              {totalRecords > 0 && (
                <GrDocumentExcel
                  onClick={() => getPayments("download")}
                  style={{ width: "70px", height: "30px", cursor: "pointer" }}
                  title="Download Excel report"
                  className="react-icons green"
                />
              )}
            </Col>
          </Row>
        </div>
      </div>
      <div className="card">
        <div
          className="card-header white-color"
          style={{
            display: "flex",
            justifyContent: "flex-end",
            padding: "1rem 1.7rem",
          }}
        >
          <h4 className="card-title mb-0" style={{ fontSize: "16px" }}>
            Payment Records | {totalRecords ?? 0}
          </h4>
          <button
            type="button"
            className="btn app-btn-default dark-color "
            onClick={handleShow}
          >
            Add New
          </button>
          <a
            ref={clickToDownload}
            href="/"
            target="_blank"
            className="hiddenItem"
          >
            HiddenClick
          </a>
        </div>
        <div className="card-body px-0 vehicle-requests-table pt-0 pb-0">
          <div className="row">
            <div className="col">
              <Table responsive striped>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Member</th>
                    <th>Service</th>
                    <th>Duration</th>
                    <th>Amount</th>
                    <th>Service Start Date</th>
                    <th>Service End Date</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {paymentList.map((item, index) => {
                    return (
                      <tr key={item?.id}>
                        <td>
                          {(currentPage - 1) * TABLE_DEFAULT_LIMIT +
                            (index + 1)}
                        </td>
                        <td>{item?.Customer?.name}</td>
                        <td>{item?.Service?.name}</td>
                        <td>{item?.serviceDuration || "-"}</td>
                        <td>{item?.amount}</td>
                        <td>
                          {new Date(item?.serviceStartDate).toLocaleDateString(
                            "en-GB"
                          )}
                        </td>
                        <td>
                          {new Date(item?.serviceEndDate).toLocaleDateString(
                            "en-GB"
                          )}
                        </td>
                        <td className="actions">
                          <Link
                            onClick={(e) =>
                              handleConfirmDeleteModal(e, item?.id)
                            }
                            className="delete"
                            to="/"
                          >
                            <DeleteIcon />
                          </Link>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
              <PaginationInPage
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                totalRecords={totalRecords}
                TABLE_DEFAULT_LIMIT={TABLE_DEFAULT_LIMIT}
              />
            </div>
          </div>
        </div>
      </div>
      <Modal
        size="lg"
        show={showConfirmModel}
        backdrop="static"
        keyboard={false}
        className="request-modal"
      >
        <Modal.Header closeButton onClick={handleClose}>
          <Modal.Title>Add Payment Record</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form autoComplete="off">
            <Row>
              <Col xs={4}>
                <FloatingLabel
                  controlId="floatingInput"
                  label="Branch's Name"
                  className="mb-3"
                >
                  <select
                    name="branchId"
                    value={paymentForm.branchId}
                    onChange={handleChange}
                    aria-label="Floating label select example"
                    className="form-select"
                    required
                  >
                    <option value="">Select Branch</option>
                    {branchList.map((item) => {
                      return (
                        <option key={item?.id} value={item.id}>
                          {item?.name}
                        </option>
                      );
                    })}
                  </select>
                </FloatingLabel>
              </Col>
              <Col xs={4}>
                {/* <FloatingLabel controlId="floatingInput" label="Customer's Name" className="mb-3">
                  <Form.Select name="customerId" value={paymentForm.customerId} onChange={handleChange} aria-label="Floating label select example" className="form-select" required>
                    <option value=''>Select Member</option>
                    {customers.map((item) => {
                      return (
                        <option key={item?.id} value={item?.id}>{item?.name}</option>
                      )
                    })
                    }
                  </Form.Select>

                </FloatingLabel> */}
                <Select
                  onChange={handleChange2}
                  className="basic-single"
                  classNamePrefix="select"
                  defaultValue={
                    paymentForm.customerId
                      ? {
                          label: "Selected Member",
                          value: paymentForm.customerId,
                        }
                      : null
                  }
                  isSearchable
                  isClearable
                  name="customerId"
                  options={customers.map((item) => ({
                    label: item?.name,
                    value: item?.id,
                  }))}
                  placeholder="Select Member"
                  styles={{
                    control: (provided, state) => ({
                      ...provided,
                      minHeight: "55px", // Adjust the height value as needed
                    }),
                  }}
                />
              </Col>
              <Col xs={4}>
                <FloatingLabel
                  controlId="floatingInput"
                  label="Service's Name"
                  className="mb-3"
                >
                  <Form.Select
                    name="serviceId"
                    value={paymentForm.serviceId}
                    onChange={handleChange}
                    aria-label="Floating label select example"
                    className="form-select"
                    required
                  >
                    <option value="">Select Service</option>
                    {services.map((item) => (
                      <option key={item?.id} value={item?.id}>
                        {item?.name}
                      </option>
                    ))}
                  </Form.Select>
                </FloatingLabel>
              </Col>
            </Row>
            <Row>
              <Col xs={4}>
                <FloatingLabel
                  controlId="floatingInput"
                  label="Service's Duration"
                  className="mb-3"
                >
                  <select
                    name="serviceDuration"
                    value={paymentForm.serviceDuration}
                    onChange={handleChange}
                    aria-label="Floating label select example"
                    className="form-select"
                    required
                  >
                    <option value="">Select Duration</option>
                    <option value="monthly">1 Month</option>
                    <option value="quarterly">3 Months</option>
                    <option value="half_yearly">6 Months</option>
                    <option value="yearly">12 Months</option>
                  </select>
                </FloatingLabel>
              </Col>
              <Col xs={4}>
                <FloatingLabel
                  controlId="floatingInput"
                  label="Amount"
                  className="mb-3"
                >
                  <Form.Control
                    required
                    className="input"
                    value={paymentForm.amount}
                    name="amount"
                    onChange={handleChange}
                    type="number"
                    placeholder="Amount"
                  />
                </FloatingLabel>
              </Col>
              <Col xs={4}>
                <FloatingLabel
                  controlId="floatingInput"
                  label="Service Plan Start Date"
                  className="mb-3"
                >
                  <Form.Control
                    required
                    className="input"
                    value={paymentForm.serviceStartDate}
                    name="serviceStartDate"
                    onChange={handleChange}
                    type="date"
                    placeholder="Date"
                  />
                </FloatingLabel>
              </Col>
            </Row>
            {paymentForm?.customerId !== "" && (
              <p style={{ color: "red", fontSize: "11px" }}>
                Last service plan of Mr/Mrs <b>{customerData?.name}</b> for{" "}
                <b>{customerData?.Service?.name}</b> expires on{" "}
                <b>
                  {new Date(customerData?.due_date).toLocaleDateString("en-GB")}
                </b>
              </p>
            )}
            <div className="container text-center">
              <Button
                type="submit"
                onClick={handleSubmit}
                className="add-request-btn mt-4 mb-3"
              >
                {"id" in paymentForm ? "Update" : "Add Payment"}
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
      <Modal
        size="sm"
        show={deleteModel}
        backdrop="static"
        keyboard={false}
        className="request-modal"
      >
        <Modal.Header className="bg-danger text-light py-2">
          <Modal.Title>Delete Payment Record</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex justify-content-start">
            <img src={AlertIcon} alt="alert icon" />
            <p className="ps-3 mb-0">Are you sure? Action cannot be undone.</p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="btn-wrapper">
            <button
              onClick={() => setDeleteModel(false)}
              className="btn app-btn-default btn-sm"
            >
              Cancel
            </button>
            <button
              onClick={(event) => handleRemove(event, idToDeleted)}
              className="btn app-btn-danger btn-sm"
            >
              Delete
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
};

export default Payment;
