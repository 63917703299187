import React, { useEffect, useRef, useState } from "react";
import { get, post, put } from "../services/apiController";
import { TABLE_DEFAULT_LIMIT, DEBOUNCE_DELAY } from "../util/constant";
import {
  Button,
  Col,
  FloatingLabel,
  Form,
  Modal,
  Row,
  Table,
} from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";
import { DeleteIcon, EditIcon, ViewIcon } from "./icons";
import { Link } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import PaginationInPage from "./Pagination";
import useDebounce from "../util/helper";
import CustomerViewModal from "../components/modals/CustomerView";
import Branch from "../components/Branch";
import { GrDocumentExcel } from "react-icons/gr";
import DeleteModal from "../components/modals/DeleteModal";

const Customer = () => {
  const formFields = {
    name: "",
    email: "",
    image: "",
    branchId: "",
    phone: "",
    role: "",
    dob: "",
    address: "",
    gender: "",
    occupation: "",
    chest: "",
    biceps: "",
    waist: "",
    weight: "",
    admissionDate: "",
    serviceId: "",
    serviceDuration: "",
    amount: "",
  };
  const clickToDownload = useRef(null);
  const [totalRecords, setTotalRecords] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [customerForm, setCustomerForm] = useState(formFields);
  const [showConfirmModel, setShowConfirmModel] = useState(false);
  const [idToDeleted, setIdToDeleted] = useState("");
  const [deleteModel, setDeleteModel] = useState(false);
  const [customerList, setCustomerList] = useState([]);
  const [services, setServices] = useState([]);
  const [amounts, setAmounts] = useState({});
  const [showViewModal, setShowViewModal] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState(null);

  // Function to handle opening the view modal
  const handleView = (customer) => {
    setSelectedCustomer(customer);
    setShowViewModal(true);
  };

  // Function to handle closing the view modal
  const handleCloseViewModal = () => {
    setSelectedCustomer(null);
    setShowViewModal(false);
  };

  const [state, setState] = useState({
    query: "",
    daysDuration: "",
  });

  const [customerFormData, setCustomerFormData] = useState({
    maritalstatus: "",
    anniversary: "",
  });

  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const onChangeFile = (e) => {
    e.preventDefault();
    const file = e.target.files[0];
    convertToBase64(file)
      .then((base64Image) => {
        setCustomerForm({
          ...customerForm,
          image: base64Image.split("base64,")[1],
        });
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const handleShow = () => {
    setShowConfirmModel(true);
    setCustomerForm(formFields);
    setServices([]);
    setAmounts({});
  };

  const handleClose = () => {
    setShowConfirmModel(false);
  };

  const handleEdit = (e, id) => {
    e.preventDefault();
    try {
      get("/customers/?id=" + id).then((customer) => {
        if (customer.data.rows.length) {
          const customerData = customer.data.rows[0];
          setCustomerForm(customerData);
          setServices([]);
          setAmounts({});
          setShowConfirmModel(true);
          loadBranchServices(customerData.branchId);
          loadServiceAmounts(customerData.serviceId);
        } else {
          toast.error("No Customer found with this name");
        }
      });
    } catch (error) {
      toast.error(
        error?.response?.data?.data?.rows?.message || "Something went wrong"
      );
    }
  };

  const loadBranchServices = (branchId) => {
    get(`/services/?branchId=${branchId}`).then((response) => {
      setServices(response?.data?.rows);
    });
  };

  const loadServiceAmounts = (serviceId) => {
    get(`/services/?id=${serviceId}`).then((res) => {
      setAmounts(res.data.rows[0]);
    });
  };

  const handleChange = async (event) => {
    event.preventDefault();
    let name = event.target.name;
    let value = event.target.value;

    setCustomerFormData((prevForm) => ({
      ...prevForm,
      [name]: value,
    }));
    let __customerForm = { ...customerForm };
    if (event.target.name === "branchId") {
      setCustomerForm({ ...__customerForm, [name]: value });
      loadBranchServices(value);
    } else if (event.target.name === "serviceId") {
      setCustomerForm({
        ...__customerForm,
        [name]: value,
        serviceDuration: "",
        amount: "",
      });
      loadServiceAmounts(value);
    } else if (event.target.name === "serviceDuration") {
      let sub = event.target.value;
      setCustomerForm({
        ...customerForm,
        amount: amounts[`${sub}`],
        [name]: value,
      });
    } else {
      setCustomerForm({ ...__customerForm, [name]: value });
    }
  };

  const handleConfirmDeleteModal = (e, id) => {
    e.preventDefault();
    setDeleteModel(true);
    setIdToDeleted(id);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if ("id" in customerForm) {
      try {
        await put({ ...customerForm }, "/customers/" + customerForm.id);
        toast.success("Customer updated successfully");
        setShowConfirmModel(false);
        getCustomers();
      } catch (err) {
        toast.error(err?.response?.data?.data?.message || err.message, {
          autoClose: 2000,
        });
      }
    } else {
      try {
        await post({ ...customerForm }, "/customers/");
        toast.success("Customer added successfully");
        setShowConfirmModel(false);
        getCustomers();
      } catch (err) {
        toast.error(err?.response?.data?.data?.message || err.message, {
          autoClose: 2000,
        });
      }
    }
  };

  const getCustomers = (conditions) => {
    try {
      let url = "";
      if (conditions === "excel") {
        url = `/customers/?conditionType=download&daysDuration=${
          state.daysDuration
        }&query=${state.query}&serviceName=${encodeURIComponent(
          state.serviceName
        )}`;
      } else {
        url = `/customers/?page=${currentPage}&limit=${TABLE_DEFAULT_LIMIT}&daysDuration=${
          state.daysDuration
        }&query=${state.query}&serviceName=${encodeURIComponent(
          state.serviceName
        )}&status=${encodeURIComponent(
          state.status
        )}`;
      }
      get(url)
        .then((response) => {
          if (conditions === "excel") {
            clickToDownload.current.href = `${process.env.REACT_APP_BASE_URL}/file/download?filePath=${response.data.filePath}`;
            setTimeout(() => {
              clickToDownload.current.click();
            }, 500);
          } else {
            setCustomerList(response.data.rows);
            setTotalRecords(response.data.count);
          }
        })
        .catch((err) => console.log("err", err));
    } catch (err) {
      toast.error(err?.response?.data?.data?.message);
    }
  };

  useDebounce(
    () => {
      if (currentPage !== 1) {
        setCurrentPage(1);
      } else {
        getCustomers();
      }
    },
    [state],
    DEBOUNCE_DELAY
  );

  useEffect(() => {
    getCustomers();
  }, [currentPage]);

  return (
    <React.Fragment>
      <ToastContainer />

      <div className="card">
        <div
          className="card-header white-color"
          style={{
            display: "flex",
            justifyContent: "flex-end",
            padding: "1rem 1.7rem",
            height: "75px",
          }}
        >
          <h4 className="card-title mb-0" style={{ fontSize: "16px" }}>
            Members | {totalRecords ?? 0}
          </h4>
          <Form.Select
            className="input"
            style={{ width: "200px", marginRight: "20px" }}
            name="serviceName"
            value={state.serviceName}
            onChange={(e) =>
              setState({ ...state, serviceName: e.target.value })
            }
            placeholder="Filter Member"
          >
            <option value="">Service Name</option>
            <option value="Gym">Gym</option>
            <option value="Gym + Cardio">Gym + Cardio</option>
            <option value="Zumba + Yoga">Zumba + Yoga</option>
          </Form.Select>
          <Form.Control
            value={state.query}
            onChange={(e) => setState({ ...state, query: e.target.value })}
            type="text"
            placeholder="Search by name, email, phone"
            style={{ width: "230px", marginRight: "20px" }}
          />
          <Form.Select
            className="input"
            style={{ width: "180px", marginRight: "20px" }}
            name="serviceName"
            value={state.status}
            onChange={(e) =>
              setState({ ...state, status: e.target.value })
            }
            placeholder="Filter Member"
          >
            <option value="">Status</option>
            <option value="Active">Acitive</option>
            <option value="inActive">inActive</option>
            
          </Form.Select>
          <Form.Select
            className="input"
            style={{ width: "200px", marginRight: "20px" }}
            name="daysDuration"
            value={state.daysDuration}
            onChange={(e) =>
              setState({ ...state, daysDuration: e.target.value })
            }
            placeholder="Filter Members"
          >
            <option value="">Select Duration</option>
            <option value="expired">Expired</option>
            <option value="0">Today</option>
            {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((day) => (
              <option value={day}>
                Due in {day} {day === 1 ? "Day" : "Days"}
              </option>
            ))}
          </Form.Select>
          
          <button
            type="button"
            className="btn app-btn-default dark-color  "
            onClick={handleShow}
            style={{  width: '150px' }}
          >
          Add New Member
          </button>
          <a
            ref={clickToDownload}
            href="/"
            target="_blank"
            className="hiddenItem"
          >
            HiddenClick
          </a>
          <GrDocumentExcel
            onClick={() => getCustomers("excel")}
            style={{ width: "70px", height: "30px", cursor: "pointer" }}
            title="Download Excel report"
            className="react-icons green"
          />
        </div>
        <div className="card-body px-0 vehicle-requests-table pt-0 pb-0">
          <div className="row">
            <div className="col">
              <Table responsive striped>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Member Name</th>
                    <th>Email</th>
                    <th>Branch Name</th>
                    <th>Age</th>
                    <th>Phone No.</th>
                    <th>Address</th>
                    <th>Marital Status</th>
                    <th>Admission Date</th>
                    <th>Service Name</th>
                    <th>Service Duration</th>
                    <th>Amount</th>
                    <th>Next Due Date</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {customerList.map((item, index) => {
                    return (
                      <tr key={item?.id}>
                        <td>
                          {(currentPage - 1) * TABLE_DEFAULT_LIMIT +
                            (index + 1)}
                        </td>
                        <td>{item?.name}</td>
                        <td>{item?.email}</td>
                        <td>{item?.Branch?.name}</td>
                        <td>{item?.age}</td>
                        <td>{item?.phone}</td>
                        <td>{item?.address}</td>
                        <td>{item?.maritalstatus}</td>
                        <td>
                          {new Date(item?.admissionDate).toLocaleDateString(
                            "en-GB"
                          )}
                        </td>
                        <td>{item?.Service?.name}</td>
                        <td>
                          {item?.serviceDuration === "yearly"
                            ? "12 Months"
                            : item?.serviceDuration === "half_yearly"
                            ? "6 Months"
                            : item?.serviceDuration === "quarterly"
                            ? "3 Months"
                            : "1 Month"}
                        </td>
                        <td>{item?.amount}</td>
                        <td>
                          {new Date(item?.due_date).toLocaleDateString("en-GB")}
                        </td>
                        <td className="actions">
                          {/* <button onClick={() => handleView(item)}>
                          <ViewIcon />
            </button> */}

                          <Link onClick={() => handleView(item)}>
                            <ViewIcon />
                          </Link>
                          <span
                            onClick={(e) => handleEdit(e, item?.id)}
                            className="edit-icon"
                          >
                            <EditIcon />
                          </span>
                          <Link
                            onClick={(e) =>
                              handleConfirmDeleteModal(e, item?.id)
                            }
                            className="delete"
                            to="/"
                          >
                            <DeleteIcon />
                          </Link>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
              <PaginationInPage
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                totalRecords={totalRecords}
                TABLE_DEFAULT_LIMIT={TABLE_DEFAULT_LIMIT}
              />
            </div>
          </div>
        </div>
      </div>
      <Modal
        size="lg"
        show={showConfirmModel}
        backdrop="static"
        keyboard={false}
        className="request-modal"
      >
        <Modal.Header closeButton onClick={handleClose}>
          <Modal.Title>
            {"id" in customerForm ? "Edit Member" : "Add Member"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form autoComplete="off">
            <Row>
              <Col xs={4}>
                <FloatingLabel
                  controlId="floatingInput"
                  label="Member Name"
                  className="mb-3"
                >
                  <Form.Control
                    className="input"
                    value={customerForm.name}
                    name="name"
                    onChange={handleChange}
                    type="text"
                    placeholder="Member Name"
                    required
                  />
                </FloatingLabel>
              </Col>
              <Col xs={4}>
                <FloatingLabel
                  controlId="floatingInput"
                  label="Email"
                  className="mb-3"
                >
                  <Form.Control
                    className="input"
                    value={customerForm.email}
                    name="email"
                    onChange={handleChange}
                    type="email"
                    placeholder="Email"
                  />
                </FloatingLabel>
              </Col>
              <Col xs={4}>
                <FloatingLabel
                  controlId="floatingInput"
                  label="Branch's Name"
                  className="mb-3"
                >
                  <Branch
                    branchId={customerForm.branchId}
                    onChange={handleChange}
                    name="branchId"
                    disabled={"id" in customerForm}
                    required={true}
                  />
                </FloatingLabel>
              </Col>
            </Row>
            <Row>
              <Col xs={4}>
                <FloatingLabel
                  controlId="floatingInput"
                  label="Service's Name"
                  className="mb-3"
                >
                  <Form.Select
                    name="serviceId"
                    value={customerForm.serviceId}
                    onChange={handleChange}
                    aria-label="Floating label select example"
                    className="form-select"
                    required
                  >
                    <option value="">Select Service</option>
                    {services.map((item) => {
                      return (
                        <option key={item?.id} value={item?.id}>
                          {item?.name}
                        </option>
                      );
                    })}
                  </Form.Select>
                </FloatingLabel>
              </Col>
              <Col xs={4}>
                <FloatingLabel
                  controlId="floatingInput"
                  label="Service's Duration"
                  className="mb-3"
                >
                  <select
                    name="serviceDuration"
                    value={customerForm.serviceDuration}
                    onChange={handleChange}
                    aria-label="Floating label select example"
                    className="form-select"
                    required
                  >
                    <option value="">Select Duration</option>
                    <option value="monthly">1 Month</option>
                    <option value="quarterly">3 Months</option>
                    <option value="half_yearly">6 Months</option>
                    <option value="yearly">12 Months</option>
                  </select>
                </FloatingLabel>
              </Col>
              <Col xs={4}>
                <FloatingLabel
                  controlId="floatingInput"
                  label="Amount"
                  className="mb-3"
                >
                  <Form.Control
                    className="input"
                    value={customerForm.amount}
                    name="amount"
                    onChange={handleChange}
                    type="number"
                    placeholder="Amount"
                  />
                </FloatingLabel>
              </Col>
            </Row>
            <Row>
              <Col xs={4}>
                <FloatingLabel
                  controlId="floatingInput"
                  label="Phone No."
                  className="mb-3"
                >
                  <Form.Control
                    className="input"
                    name="phone"
                    value={customerForm.phone}
                    onChange={handleChange}
                    type="text"
                    placeholder="Phone No."
                    required
                  />
                </FloatingLabel>
              </Col>
              <Col xs={4}>
                <FloatingLabel
                  controlId="floatingInput"
                  label="Admisson Date"
                  className="mb-3"
                >
                  <Form.Control
                    disabled={"id" in customerForm}
                    className="input"
                    value={customerForm.admissionDate}
                    name="admissionDate"
                    onChange={handleChange}
                    type="date"
                    placeholder="Admission Date"
                    required
                  />
                </FloatingLabel>
              </Col>
              <Col xs={4}>
                <FloatingLabel
                  controlId="formFile"
                  label="Upload image"
                  className="images-box mb-3"
                >
                  <Form.Control
                    onChange={(e) => onChangeFile(e)}
                    required
                    type="file"
                    name="image"
                    aria-required="false"
                  />
                </FloatingLabel>
              </Col>
            </Row>
            <Row>
              <Col xs={4}>
                <FloatingLabel
                  controlId="floatingInput"
                  label="Address"
                  className="mb-3"
                >
                  <Form.Control
                    className="input"
                    value={customerForm.address}
                    name="address"
                    onChange={handleChange}
                    type="text"
                    placeholder="Address"
                  />
                </FloatingLabel>
              </Col>
              <Col xs={4}>
                <FloatingLabel
                  controlId="floatingInput"
                  label="Date of Birth"
                  className="mb-3"
                >
                  <Form.Control
                    className="input"
                    value={customerForm.dob}
                    name="dob"
                    onChange={handleChange}
                    type="date"
                    placeholder="D.O.B."
                  />
                </FloatingLabel>
              </Col>
              <Col xs={4}>
                <FloatingLabel
                  controlId="floatingInput"
                  label="Occupation"
                  className="mb-3"
                >
                  <Form.Control
                    className="input"
                    value={customerForm.occupation}
                    name="occupation"
                    onChange={handleChange}
                    type="text"
                    placeholder="Occupation"
                  />
                </FloatingLabel>
              </Col>
            </Row>
            <Row>
              <Col xs={4}>
                <FloatingLabel
                  controlId="floatingInput"
                  label="Gender"
                  className="mb-3"
                >
                  <select
                    name="gender"
                    value={customerForm.gender}
                    onChange={handleChange}
                    aria-label="Floating label select example"
                    className="form-select"
                  >
                    <option value="">Select</option>
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                  </select>
                </FloatingLabel>
              </Col>
              <Col xs={4}>
                <FloatingLabel
                  controlId="floatingInput"
                  label="Weight"
                  className="mb-3"
                >
                  <Form.Control
                    className="input"
                    value={customerForm.weight}
                    name="weight"
                    onChange={handleChange}
                    type="number"
                    placeholder="Weight"
                  />
                </FloatingLabel>
              </Col>
              <Col xs={4}>
                <FloatingLabel
                  controlId="floatingInput"
                  label="Chest"
                  className="mb-3"
                >
                  <Form.Control
                    className="input"
                    value={customerForm.chest}
                    name="chest"
                    onChange={handleChange}
                    type="number"
                    placeholder="Chest"
                  />
                </FloatingLabel>
              </Col>
              <Col xs={4}>
                <FloatingLabel
                  controlId="floatingInput"
                  label="Biceps"
                  className="mb-3"
                >
                  <Form.Control
                    className="input"
                    value={customerForm.biceps}
                    name="biceps"
                    onChange={handleChange}
                    type="number"
                    placeholder="Biceps"
                  />
                </FloatingLabel>
              </Col>
              <Col xs={4}>
                <FloatingLabel
                  controlId="floatingInput"
                  label="Waist"
                  className="mb-3"
                >
                  <Form.Control
                    className="input"
                    value={customerForm.waist}
                    name="waist"
                    onChange={handleChange}
                    type="number"
                    placeholder="Waist"
                  />
                </FloatingLabel>
              </Col>

              <Col xs={4}>
                <FloatingLabel
                  controlId="floatingInput"
                  label="Marital Status"
                  className="mb-3"
                >
                  <select
                    name="maritalstatus"
                    value={customerFormData.maritalstatus}
                    onChange={handleChange}
                    aria-label="Floating label select example"
                    className="form-select"
                  >
                    <option value="">Select</option>
                    <option value="Married">Married</option>
                    <option value="UnMarried">UnMarried</option>
                  </select>
                </FloatingLabel>
              </Col>

              {customerFormData.maritalstatus === "Married" && (
                <Col xs={4}>
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Anniversary Date"
                    className="mb-3"
                  >
                    <Form.Control
                      className="input"
                      value={customerFormData.anniversary}
                      name="anniversary"
                      onChange={handleChange}
                      type="date"
                      placeholder="Anniversary"
                    />
                  </FloatingLabel>
                </Col>
              )}
            </Row>
            <div className="container text-center">
              <Button
                type="submit"
                onClick={handleSubmit}
                className="add-request-btn mt-4 mb-3"
              >
                {"id" in customerForm ? "Update" : "Add"}
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
      {deleteModel && (
        <DeleteModal
          title={"Delete Customer"}
          description={"Are you sure? Action cannot be undone."}
          deleteModal={deleteModel}
          setDeleteModal={setDeleteModel}
          idToDeleted={idToDeleted}
          url={"/customers"}
          callback={() => getCustomers()}
        />
      )}
      {/* Render customer list with view button */}
      {customerList.map((customer, index) => (
        <tr key={customer.id}>
          {/* Other table cells */}
          <td className="actions">
            {/* <button onClick={() => handleView(customer)}>
              <ViewIcon />
            </button> */}
          </td>
        </tr>
      ))}

      {/* View Customer Modal */}
      <CustomerViewModal
        showViewModal={showViewModal}
        selectedCustomer={selectedCustomer}
        handleCloseViewModal={handleCloseViewModal}
      />
    </React.Fragment>
  );
};

export default Customer;
