import { Pagination } from "react-bootstrap";

export default function PaginationInPage({
  currentPage,
  setCurrentPage,
  totalRecords,
  TABLE_DEFAULT_LIMIT,
}) {
  return (
    <>
      <Pagination size="sm">
        <Pagination.First
          disabled={currentPage === 1}
          onClick={() => {
            setCurrentPage(1);
          }}
        />
        <Pagination.Prev
          disabled={currentPage === 1}
          onClick={() => {
            setCurrentPage(currentPage - 1);
          }}
        />
        <Pagination.Item disabled>
          Page {currentPage} of {Math.ceil(totalRecords / TABLE_DEFAULT_LIMIT)}
        </Pagination.Item>
        <Pagination.Next
          disabled={
            currentPage === Math.ceil(totalRecords / TABLE_DEFAULT_LIMIT)
          }
          onClick={() => {
            setCurrentPage(currentPage + 1);
          }}
        />
        <Pagination.Last
          disabled={
            currentPage === Math.ceil(totalRecords / TABLE_DEFAULT_LIMIT)
          }
          onClick={() => {
            setCurrentPage(Math.ceil(totalRecords / TABLE_DEFAULT_LIMIT));
          }}
        />
      </Pagination>
    </>
  );
}
