import React, { useMemo } from "react";
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";
import { get_auth_key, getStoredData } from "../util";

const menu_list = [
  {
    id: "1",
    label: "Branches",
    link: "/branches",
  },
  {
    id: "2",
    label: "Users",
    link: "/users",
  },
  {
    id: "3",
    label: "Services",
    link: "/services",
  },
  {
    id: "7",
    label: "Visitors",
    link: "/visitors",
  },
  {
    id: "4",
    label: "Members",
    link: "/members",
  },
  {
    id: "5",
    label: "Payments",
    link: "/payments",
  },
  {
    id: "6",
    label: "Celebration",
    link: "/celebration",
  },
  {
    id: "8",
    label: "Events",
    link: "/events",
  },
];

const managerMenu = [
  {
    id: "3",
    label: "Visitors",
    link: "/visitors",
  },
  {
    id: "1",
    label: "Members",
    link: "/members",
  },
  {
    id: "2",
    label: "Payments",
    link: "/payments",
  },
  {
    id: "6",
    label: "Celebration",
    link: "/celebration",
  },
  {
    id: "7",
    label: "Events",
    link: "/events",
  },
];

const SibeBar = (props) => {
  const hiddenMenu = useSelector((state) => state.menu.isMenuOpen);
  const storedData = JSON.parse(getStoredData(get_auth_key()));

  let isAdmin = false;
  if (storedData?.role === "admin") {
    isAdmin = true;
  } else {
    isAdmin = false;
  }

  const menu = useMemo(() => {
    if (isAdmin) {
      return menu_list;
    } else {
      return managerMenu;
    }
  }, [managerMenu]);

  return (
    <div
      className={
        hiddenMenu === false ? "menu-wrapper" : "menu-wrapper menu-hidden"
      }
    >
      <ul className="list">
        {menu.map((item, index) => {
          return (
            <li className="menu-item" key={`menu_${item.id}_${index}`}>
              <NavLink to={item.link} className="menu-link">
                {item.label}
              </NavLink>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default SibeBar;
